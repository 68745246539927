import { FC, ChangeEvent, useState, useEffect } from "react";
import { isAxiosError } from "axios";
import { useDebouncedCallback } from "use-debounce";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  settings,
  contacts,
} from "store";
import { setRecipients, setEditedContact } from "store/requestSigning";
import { getContacts } from "store/contacts/thunks";

import { Icon, Input, Button, Checkbox, Modal } from "components/UI";
import { UpdateContactModal } from "components";
import { Contacts } from "api";
import { toastError, validateEmail, cs, sleep } from "utils";
import { IContact, IRecipient } from "types";
import { useEffectOnce, useSigningsRequest } from "hooks";

import styles from "./styles.module.scss";

export const RecipientsList: FC = () => {
  const dispatch = useAppDispatch();
  const { allContacts } = useAppSelector(contacts);
  const { user } = useAppSelector(settings);
  const { recipients, editedDraft, editedContact } =
    useAppSelector(requestSigning);
  const { updateContacts } = useSigningsRequest();
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsList",
  });
  const { t: tG } = useTranslation("General");
  const [suggestionsFor, setSuggestionsFor] = useState<string>("");
  const [contactsList, setContactsList] = useState<IContact[]>([]);
  const [isSelfSigner, setIsSelfSigner] = useState<boolean>(false);
  const [isSelfSignerInitialized, setIsSelfSignerInitialized] =
    useState<boolean>(false);
  const [isSelectUsed, setIsSelectUsed] = useState<boolean>(false);

  const handleSearchSuggestions = useDebouncedCallback(
    async (value: string) => {
      if (value) {
        try {
          const res = await Contacts.searchContact({ query: value });
          if (res?.items) {
            setContactsList(
              res.items.filter(
                (item) => !recipients.some((el) => el.email === item.email),
              ),
            );
          }
        } catch (error) {
          console.log("error:", error);
          if (isAxiosError(error)) {
            error?.message &&
              toastError(
                Array.isArray(error.message) ? error.message[0] : error.message,
              );
          }
        }
      }
    },
    500,
  );

  useEffectOnce(() => {
    dispatch(getContacts());
  });

  useEffect(() => {
    const isOwnerAsRecipient =
      recipients?.length > 0 &&
      recipients.some((el) => el.email === user?.email);
    if (
      recipients.length > 0 &&
      user &&
      isOwnerAsRecipient &&
      !isSelfSignerInitialized
    ) {
      setIsSelfSigner(true);
      setIsSelfSignerInitialized(true);
    }
  }, [recipients, user, isSelfSignerInitialized]);

  useEffect(() => {
    if (editedDraft) {
      const stateRecipents = editedDraft.recipients?.map((el) => ({
        ...el,
        id: allContacts?.find((item) => el.email === item.email)?.id || "",
      }));
      dispatch(setRecipients(stateRecipents));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editedDraft]);

  const checkEmailValidity = (index: number) => {
    const res = [...recipients];
    const emailValidationErrors =
      res[index].email && validateEmail(res[index].email);

    if (emailValidationErrors) {
      res[index] = {
        ...res[index],
        error: res[index].error || emailValidationErrors[0],
      };
    }

    if (res[index].email === user?.email) {
      res[index] = {
        ...res[index],
        error: t("emailOwnerError"),
      };
    }
    dispatch(setRecipients(res));
  };

  const handleChange = async (
    { target }: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (target.value?.length > 100) return;
    const res = [...recipients];
    res[index] = { ...res[index], [target.name]: target.value };
    if (
      target.name === "email" &&
      recipients.some((el) => el.email === target.value)
    ) {
      res[index] = {
        ...res[index],
        error: t("recipientError"),
      };
    } else if (target.name === "email") {
      res[index] = {
        ...res[index],
        error: "",
      };
    }
    setSuggestionsFor(`${target.name}${index}`);
    handleSearchSuggestions(target.value);
    dispatch(setRecipients(res));
  };

  const handleAddRecipient = () => {
    dispatch(
      setRecipients([
        ...recipients,
        {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
          error: "",
        },
      ]),
    );
  };

  const handleRemoveRecipient = (index: number) => {
    const res = [...recipients];
    res.splice(index, 1);
    setIsSelfSigner(res.some((el) => el.email === user?.email));
    dispatch(setRecipients(res || []));
  };

  const handleCloseSuggestions = () => {
    setContactsList([]);
    setSuggestionsFor("");
  };

  const handleSelectContact = (
    { id, firstName, lastName, email }: IContact,
    index: number,
  ) => {
    const res = [...recipients];
    res[index] = {
      id,
      firstName,
      lastName,
      email,
      error: "",
    };
    dispatch(setRecipients(res));
    setIsSelfSigner(res.some((el) => el.email === user?.email));
    handleCloseSuggestions();
  };

  const handleToggleSelf = () => {
    if (user) {
      const filteredRecipients = recipients.filter(
        (el) => el.email !== user.email,
      );
      const contactsUser = allContacts?.find((el) => el.email === user.email);
      const userAsRecipient = {
        id: contactsUser?.id || "",
        firstName: contactsUser?.firstName || "",
        lastName: contactsUser?.lastName || "",
        email: contactsUser?.email || "",
        error: "",
      };
      if (isSelfSigner) {
        dispatch(setRecipients(filteredRecipients));
      } else {
        dispatch(setRecipients([userAsRecipient, ...filteredRecipients]));
      }
    }
    setIsSelfSigner((prevState) => !prevState);
  };

  const handleCloseEditContactModal = () => {
    dispatch(setEditedContact(null));
  };

  const handleCancelEditContactModal = async () => {
    if (editedContact) {
      const newRecipients = [...recipients];
      const changedRecipientIndex =
        (editedContact &&
          newRecipients.findIndex((el) => el.id === editedContact.id)) ||
        0;
      newRecipients[changedRecipientIndex] = {
        id: editedContact.id,
        firstName: editedContact.oldFirstName,
        lastName: editedContact.oldLastName,
        email: editedContact.oldEmail,
        error: "",
      };
      dispatch(setRecipients(newRecipients));
    }
    await sleep(100);
    handleCloseEditContactModal();
  };

  const handleSubmitEditContactModal = () => {
    handleCloseEditContactModal();
    updateContacts();
  };

  const handleBlurName = () => {
    if (!isSelectUsed && !editedContact) {
      updateContacts();
    }
  };

  const handleBlurEmail = async (item: IRecipient, index: number) => {
    if (item.id !== user?.id) {
      checkEmailValidity(index);
    }
    if (!isSelectUsed && !editedContact) {
      updateContacts();
    }
  };

  const handleFocus = () => {
    setIsSelectUsed(false);
  };

  const renderSuggestions = (index: number) => {
    if (contactsList.length > 0) {
      if (!isSelectUsed) {
        setIsSelectUsed(true);
      }

      return (
        <OutsideClickHandler onOutsideClick={handleCloseSuggestions}>
          <div className={styles.suggestions}>
            {contactsList.map((item) => (
              <div
                key={item.id}
                className={styles.contact}
                onClick={() => handleSelectContact(item, index)}
              >
                <div className={styles.name}>
                  {item.firstName} {item.lastName}
                </div>
                <div className={styles.email}>{item.email}</div>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  return (
    <div className={styles.RecipientsList}>
      <Modal isShowed={!!editedContact}>
        <UpdateContactModal
          onCancel={handleCancelEditContactModal}
          onSubmit={handleSubmitEditContactModal}
        />
      </Modal>
      <div className={styles.selfSigning}>
        <div className={styles.checkbox}>
          <Checkbox
            name="selfSigning"
            onChange={handleToggleSelf}
            isChecked={isSelfSigner}
          />
          <div className={styles.checkboxLabel}>{t("checkboxLabel")}</div>
        </div>
        <Icon
          name="info"
          className={styles.action}
          dataTooltipId="selfSigning"
          dataTooltipContent={t("tooltipContent")}
          dataTooltipPlace="bottom-start"
          size={20}
        />
      </div>

      <h2 className={styles.title}>{t("title")}</h2>

      {recipients.map((item, index) => (
        <div className={styles.inputsWrap} key={index}>
          <div className={cs([styles.inputWrap, styles.plural])}>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                onChange={(e) => handleChange(e, index)}
                onBlur={handleBlurName}
                onFocus={handleFocus}
                value={item.firstName}
                name="firstName"
                placeholder={t("inputFirstNamePlaceholder")}
                label={t("inputFirstNameLabel")}
                autoComplete="off"
                isRequired
              />
              {suggestionsFor === `firstName${index}` &&
                renderSuggestions(index)}
            </div>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                onChange={(e) => handleChange(e, index)}
                onBlur={handleBlurName}
                onFocus={handleFocus}
                value={item.lastName}
                name="lastName"
                placeholder={t("inputLastNamePlaceholder")}
                label={t("inputLastNameLabel")}
                autoComplete="off"
                isRequired
              />
              {suggestionsFor === `lastName${index}` &&
                renderSuggestions(index)}
            </div>
          </div>
          <div className={styles.inputWrap}>
            <Input
              className={styles.input}
              onChange={(e) =>
                item.email === user?.email ? () => {} : handleChange(e, index)
              }
              // onBlur={() =>
              //   item.id === user?.id ? () => {} : checkEmailValidity(index)
              // }
              onBlur={() => handleBlurEmail(item, index)}
              onFocus={handleFocus}
              value={item.email}
              name="email"
              placeholder={tG("emailPlaceholder")}
              label={tG("emailLabel")}
              autoComplete="off"
              error={item.error}
              isRequired
            />
            {suggestionsFor === `email${index}` &&
              !isSelfSigner &&
              renderSuggestions(index)}
          </div>
          {/* {index > 0 && ( */}
          <div
            className={styles.clearButton}
            onClick={() => handleRemoveRecipient(index)}
          >
            <Icon name="close" size={12} />
          </div>
          {/* )} */}
        </div>
      ))}
      {recipients?.length <= 10 && (
        <Button
          className={styles.addRecipientButton}
          size="sm"
          variant="textBlack"
          title={t("addRecipientButtonTitle")}
          iconStart="add-recepient"
          iconStartType="stroke"
          onClick={handleAddRecipient}
        />
      )}
    </div>
  );
};
