import { FC, ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { useDebouncedCallback } from "use-debounce";

import { Contacts } from "api";

import { Input, Icon } from "components/UI";
import { validateEmail, toastError } from "utils";

import { IContact } from "types";

import styles from "./styles.module.scss";

type ContactSelectorProps = {
  label: string;
  isRequired?: boolean;
  recipients: IContact[];
  allRecipients: IContact[];
  setRecipients: (recipient: IContact[]) => void;
};

export const ContactSelector: FC<ContactSelectorProps> = ({
  label,
  isRequired,
  recipients,
  allRecipients,
  setRecipients,
}) => {
  const [inputText, setInputText] = useState<string>("");
  const [contactsList, setContactsList] = useState<IContact[]>([]);
  const [emailError, setEmailError] = useState<string>("");
  const [isRecipientAdded, setIsRecipientAdded] = useState<boolean>(false);
  const { t } = useTranslation("Modals", { keyPrefix: "SendEmail" });

  useEffect(() => {
    if (emailError) {
      toastError(t("invalidEmail"));
    }
  }, [emailError, t]);

  const handleSearchSuggestions = useDebouncedCallback(
    async (value: string) => {
      if (value) {
        const res = await Contacts.searchContact({ query: value });
        if (res?.items) {
          setContactsList(
            res.items.filter(
              (item) => !recipients.some((el) => el.email === item.email),
            ),
          );
        }
      }
    },
    500,
  );

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value?.length > 100) return;
    setEmailError("");
    setInputText(target.value);
    handleSearchSuggestions(target.value);
    setIsRecipientAdded(false);
  };

  const handleCloseSuggestions = () => {
    setContactsList([]);
  };

  const handleSelectRecipient = ({
    id,
    firstName,
    lastName,
    email,
  }: IContact) => {
    const res = [...recipients, { id, firstName, lastName, email }];
    setRecipients(res);
    handleCloseSuggestions();
    setIsRecipientAdded(true);
    setInputText("");
  };

  const checkEmailValidity = () => {
    const emailValidationErrors = inputText && validateEmail(inputText);

    if (emailValidationErrors.length > 0) {
      setEmailError(emailValidationErrors[0]);
    }
    return emailValidationErrors;
  };

  const handleAddEmail = async () => {
    if (inputText && !isRecipientAdded && contactsList.length === 0) {
      const errorText = checkEmailValidity();
      if (errorText.length === 0) {
        if (allRecipients.some((el) => el.email === inputText)) {
          setEmailError("duplicate");
          return;
        }
        const res = [
          ...recipients,
          {
            id: `${Date.now()}`,
            firstName: "",
            lastName: "",
            email: inputText,
          },
        ];
        setRecipients(res);
        setInputText("");
      }
    }
  };

  const handleClearRecipient = (email: string) => {
    setRecipients(recipients.filter((el) => el.email !== email));
  };

  const renderSuggestions = () => {
    const filteredContacts = contactsList.filter((el) =>
      allRecipients.every((rec) => el.email !== rec.email),
    );
    if (filteredContacts.length > 0) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseSuggestions}>
          <div className={styles.suggestions}>
            {filteredContacts.map((item) => (
              <div
                key={item.id}
                className={styles.contact}
                onClick={() => handleSelectRecipient(item)}
              >
                <div className={styles.name}>
                  {item.firstName} {item.lastName}
                </div>
                <div className={styles.email}>{item.email}</div>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  return (
    <div className={styles.ContactSelector}>
      <div className={styles.label}>
        {label}
        {isRequired && <span className={styles.labelError}> *</span>}
      </div>
      <div className={styles.container}>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            onChange={handleChange}
            onBlur={handleAddEmail}
            error={emailError && " "}
            value={inputText}
            name="namemail"
            placeholder={t("emailPlaceholder")}
            autoComplete="off"
            isAutofocus
          />
          {renderSuggestions()}
        </div>
        {recipients.length > 0 && (
          <div className={styles.recipients}>
            {recipients.map((el) => (
              <div className={styles.recipient}>
                <div>{el.email}</div>
                <Icon
                  name="close-in-circle"
                  size={20}
                  className={styles.clearButton}
                  action={() => handleClearRecipient(el.email)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
