import { FC, useEffect, useRef } from "react";

// import { REACT_APP_PSPDFKIT_ID } from "constants/index"; // update to env secret

import { useAppDispatch, useAppSelector, trashBin } from "store";

// type PdfViewerTrashDocProps = {
//   document: string;
// };

export const PdfViewerTrashDoc: FC = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);
  const { openedDocument } = useAppSelector(trashBin);

  const isLocal = window.location.href.startsWith("http://localhost:");

  useEffect(() => {
    if (openedDocument?.url) {
      const container = containerRef.current; // This `useRef` instance will render the PDF.

      let PSPDFKit: any;
      // let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");

        // View state config
        const initialViewState = new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
          pageSpacing: 10,
          spreadSpacing: 50,
        });

        // const toolbarItems = PSPDFKit.defaultToolbarItems;
        const toolbarItems = [
          { type: "sidebar-thumbnails" },
          { type: "pager" },
          { type: "zoom-out" },
          { type: "zoom-in" },
          { type: "zoom-mode" },
        ];

        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

        await PSPDFKit.load({
          container,
          document: openedDocument?.url,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          initialViewState,
          toolbarItems,
          enableClipboardActions: true,
          enableHistory: true,
          locale: "en",
          styleSheets: ["/custom-pspdfkit.css"],
          licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
        });

        return () => PSPDFKit && PSPDFKit.unload(container);
      })();
    }
  }, [openedDocument, isLocal, dispatch]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        zIndex: "100",
        position: "fixed",
        left: "0",
        top: "10rem",
      }}
    />
  );
};
