import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, requestSigning, auth } from "store";
import { getDraft } from "store/signings/thunks";
import { setIsRequestReady } from "store/requestSigning";

import { PATHES } from "constants/pathes";
import { Icon } from "components/UI";
import { palette, cs } from "utils";
import { useSigningsRequest } from "hooks";

import styles from "./styles.module.scss";

type BreadcrumbsRequestSigningProps = {
  className?: string;
};

type RouteT = {
  name: string;
  path: string;
  isDisabled: boolean;
};

export const BreadcrumbsRequestSigning: FC<BreadcrumbsRequestSigningProps> = ({
  className,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { draftId } = useParams();
  const { t } = useTranslation("RequestSigning", { keyPrefix: "Breadcrumbs" });
  const { isRequestReady, recipients } = useAppSelector(requestSigning);
  const { user } = useAppSelector(auth);
  const { getIsEmailTabDisabled } = useSigningsRequest();
  const [initailsRoutes, setInitialRoutes] = useState<RouteT[]>([]);
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);

  const renderArrow = () => (
    <Icon
      name="chevron-left-empty"
      className={styles.arrow}
      color={palette.grey70}
      size={20}
    />
  );

  const handleGoTo = (path: string, isDisabled: boolean) => {
    if (path.startsWith(PATHES.EMAIL_DETAILS) && getIsEmailTabDisabled()) {
      dispatch(setIsRequestReady(false));
      return;
    }
    if (!isDisabled) {
      draftId && dispatch(getDraft(draftId));
      navigate(path);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsEmailDisabled(getIsEmailTabDisabled());
    }, 500);
    return () => clearInterval(interval);
  }, [getIsEmailTabDisabled]);

  useEffect(() => {
    if (draftId) {
      const routes = [
        {
          name: t("fileRecipients"),
          path: draftId
            ? `${PATHES.FILE_RECIPIENTS}/${draftId}`
            : PATHES.FILE_RECIPIENTS,
          isDisabled: false,
        },
        {
          name: t("signingsArea"),
          path: `${PATHES.SIGNINGS_AREA}/${draftId}`,
          isDisabled: !draftId || recipients.length === 0,
        },
        ...(recipients.length === 1 && recipients[0].email === user?.email
          ? []
          : [
              {
                name: t("emailDetails"),
                path: `${PATHES.EMAIL_DETAILS}/${draftId}`,
                isDisabled: isEmailDisabled || !isRequestReady,
              },
            ]),
      ];
      setInitialRoutes(routes);
    }
  }, [isEmailDisabled, draftId, isRequestReady, t, recipients, user]);

  return (
    <nav className={cs([styles.BreadcrumbsRequestSigning, className])}>
      {initailsRoutes.map((item, index) => (
        <div
          key={item.name}
          className={cs([
            styles.linkWrap,
            pathname.startsWith(item.path) && styles.active,
            item.isDisabled && styles.disabled,
          ])}
        >
          <div
            className={styles.link}
            onClick={() => handleGoTo(item.path, item.isDisabled)}
          >
            <div className={styles.label}>{index + 1}</div>
            <div className={styles.text}>{item.name}</div>
          </div>
          {index < initailsRoutes.length - 1 && renderArrow()}
        </div>
      ))}
    </nav>
  );
};
