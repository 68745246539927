import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IFolderParents,
  IFolder,
  IFolderInnerData,
  IFolderDetailedData,
  DataType,
} from "types";

import {
  getFolder,
  getRootFolder,
  getFolderParents,
  getMovingFolderParents,
  createFolder,
} from "./thunks";

interface IRecipientAnnotation {
  recipient: string;
  annotations: string[];
}
interface IContextFolder {
  id: string;
  title: string;
}

export type FoldersState = {
  currentFolder: string;
  currentMovingFolder: string;
  currentContextFolder: IContextFolder | null;
  currentFolderParents: IFolderParents | null;
  currentMovingFolderParents: IFolderParents | null;
  destinationFolder: IContextFolder | null;
  isFolderMoving: boolean;
  isMovingItemsModal: boolean;
  isDeleteItemsModal: boolean;
  isSendEmailModal: boolean;
  isSendEmailModalToOpen: boolean;
  recepientsModal: string;
  rootFolder: IFolder | null;
  folderData: IFolderInnerData | null;
  allGeneralItems: IFolderInnerData[];
  allDetailedItems: IFolderDetailedData[];
  // documentURL: string;
  // documentID: string;
  recipients: string[];
  activeRecipient: string;
  recipientAnnotations: IRecipientAnnotation[];
  selectedItems: DataType[];
  itemsToMove: string[];
  renameModalItem: string;
  certifyModalItem: string;
  createModalItem: boolean;
  createdFolderId: string;
  softDeletedItems: string[];
};

const initialState: FoldersState = {
  isFolderMoving: false,
  isMovingItemsModal: false,
  isDeleteItemsModal: false,
  isSendEmailModal: false,
  isSendEmailModalToOpen: false,
  recepientsModal: "",
  // documentURL: "",
  // documentID: "",
  currentFolder: "",
  currentMovingFolder: "",
  currentContextFolder: null,
  currentFolderParents: null,
  currentMovingFolderParents: null,
  destinationFolder: null,
  rootFolder: null,
  folderData: null,
  allGeneralItems: [],
  allDetailedItems: [],
  recipients: [""],
  activeRecipient: "",
  recipientAnnotations: [],
  selectedItems: [],
  itemsToMove: [],
  renameModalItem: "",
  certifyModalItem: "",
  createModalItem: false,
  createdFolderId: "",
  softDeletedItems: [],
};

const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    setCurrentFolder(state, { payload }: PayloadAction<string>) {
      state.currentFolder = payload;
    },
    setRootId(state, { payload }: PayloadAction<IFolder | null>) {
      state.rootFolder = payload;
    },
    setDestinationFolder(
      state,
      { payload }: PayloadAction<IContextFolder | null>,
    ) {
      state.destinationFolder = payload;
    },
    setIsFolderMoving(state, { payload }: PayloadAction<boolean>) {
      state.isFolderMoving = payload;
    },
    setIsMovingItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isMovingItemsModal = payload;
    },
    setIsSendEmailModal(state, { payload }: PayloadAction<boolean>) {
      state.isSendEmailModal = payload;
    },
    setIsSendEmailModalToOpen(state, { payload }: PayloadAction<boolean>) {
      state.isSendEmailModalToOpen = payload;
    },
    setRecepientsModal(state, { payload }: PayloadAction<string>) {
      state.recepientsModal = payload;
    },
    // setDocumentURL(state, { payload }: PayloadAction<string>) {
    //   state.documentURL = payload;
    // },
    // setDocumentID(state, { payload }: PayloadAction<string>) {
    //   state.documentID = payload;
    // },
    setRecipients(state, { payload }: PayloadAction<string[]>) {
      state.recipients = payload;
    },
    setActiveRecipient(state, { payload }: PayloadAction<string>) {
      state.activeRecipient = payload;
    },
    setRecipientAnnotations(
      state,
      { payload }: PayloadAction<IRecipientAnnotation[]>,
    ) {
      state.recipientAnnotations = payload;
    },
    setAllDetailedFolders(
      state,
      { payload }: PayloadAction<IFolderDetailedData[]>,
    ) {
      state.allDetailedItems = payload;
    },
    setAllGeneralItems(state, { payload }: PayloadAction<IFolderInnerData[]>) {
      state.allGeneralItems = payload;
    },
    setSelectedItems(state, { payload }: PayloadAction<DataType[]>) {
      state.selectedItems = payload;
    },
    setItemsToMove(state, { payload }: PayloadAction<string[]>) {
      state.itemsToMove = payload;
    },
    setCurrentMovingFolder(state, { payload }: PayloadAction<string>) {
      state.currentMovingFolder = payload;
    },
    setCurrentContextFolder(
      state,
      { payload }: PayloadAction<IContextFolder | null>,
    ) {
      state.currentContextFolder = payload;
    },
    setRenameModalItem(state, { payload }: PayloadAction<string>) {
      state.renameModalItem = payload;
    },
    setCertifyModalItem(state, { payload }: PayloadAction<string>) {
      state.certifyModalItem = payload;
    },
    setCreateModalItem(state, { payload }: PayloadAction<boolean>) {
      state.createModalItem = payload;
    },
    setIsDeleteItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteItemsModal = payload;
    },
    setSoftDeletedItems(state, { payload }: PayloadAction<string[]>) {
      state.softDeletedItems = payload;
    },
    setCreatedFolderId(state, { payload }: PayloadAction<string>) {
      state.createdFolderId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolder.fulfilled, (state, action) => {
      if (action.payload?.folder?.id) {
        state.folderData = action.payload;
        state.allGeneralItems = [
          ...state.allGeneralItems.filter(
            (item) => item.folder?.id !== action.payload?.folder?.id,
          ),
          action.payload,
        ];
      }
    });
    builder.addCase(getRootFolder.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.rootFolder = action.payload;
        state.currentFolder = action.payload?.id;
      }
    });
    builder.addCase(getFolderParents.fulfilled, (state, action) => {
      state.currentFolderParents = action.payload;
    });
    builder.addCase(getMovingFolderParents.fulfilled, (state, action) => {
      state.currentMovingFolderParents = action.payload;
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      state.allDetailedItems = [
        ...new Set([...state.allDetailedItems, action.payload]),
      ];
      state.createdFolderId = action.payload.id;
    });
  },
});

export const {
  setCurrentFolder,
  setRootId,
  setDestinationFolder,
  setIsFolderMoving,
  // setDocumentURL,
  // setDocumentID,
  setAllGeneralItems,
  setRecipients,
  setActiveRecipient,
  setRecipientAnnotations,
  setSelectedItems,
  setRecepientsModal,
  setItemsToMove,
  setCurrentMovingFolder,
  setIsMovingItemsModal,
  setRenameModalItem,
  setCertifyModalItem,
  setIsDeleteItemsModal,
  setCreateModalItem,
  setCurrentContextFolder,
  setSoftDeletedItems,
  setCreatedFolderId,
  setIsSendEmailModal,
  setIsSendEmailModalToOpen,
} = foldersSlice.actions;

export default foldersSlice.reducer;
