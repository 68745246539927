/* eslint-disable no-underscore-dangle */
import {
  FC,
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Annotation, Instance } from "pspdfkit";
import fileDownload from "js-file-download";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setStoredInstance, setIsUpdated } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import { getUser } from "store/settings/thunks";
import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  signings,
  settings,
  signatures,
  auth,
} from "store";
import { ThemeContext } from "providers";

import { SharedDocuments, Signatures } from "api";
import {
  toArrayBuffer,
  palette,
  getEmptyNode,
  // getButtonNode,
  // signedStatusNode,
  sleep,
  pushGaEvent,
} from "utils";
import { useInstance, useUploadRequest, useActions } from "hooks";
import { PATHES } from "constants/pathes";

import downloadIcon from "assets/img/icons/download.svg";
import moveFolderIcon from "assets/img/pdfView/moveFolder.svg";
import printIcon from "assets/img/pdfView/print.svg";
import dateIcon from "assets/img/pdfView/date.svg";
// import signIcon from "assets/img/pdfView/sign.svg";
// import eraseIcon from "assets/img/pdfView/erase.svg";

import { RecipientsWidget } from "./RecipientsWidget";
import { SignatureWidget } from "./SignatureWidget";
import { RejectionWidget } from "./RejectionWidget";
import { RecipientSigningHeader } from "./Header";
import { RecipientSigningFooter } from "./Footer";
// import { GoNextButton } from "./GoNextButton";
import { CounterInfo } from "./CounterInfo";

type PdfViewerRecipientSigningProps = {
  isOwner?: boolean;
  isBackup?: boolean;
};

export const PdfViewerRecipientSigning: FC<PdfViewerRecipientSigningProps> = ({
  isOwner,
  isBackup,
}) => {
  const { t } = useTranslation("RecipientSigningHeader");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeRecipient } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  const { user: authUser } = useAppSelector(auth);
  const { inboxDoc, sentDoc, guestRecipientDoc, guestOwnerDoc } =
    useAppSelector(signings);
  const {
    allSignatures: allStoredSignatures,
    allGuestSignatures: allStoredGuestSignatures,
  } = useAppSelector(signatures);
  const storageisSigningStarted =
    !!sessionStorage.getItem("IsSigningStarted") || false;
  const { handleMoveInboxDocument } = useActions(inboxDoc?.id);
  const { handleMoveSentDocument } = useActions(sentDoc?.id);
  const {
    saveInkAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    deleteSignature,
    getSavedJSON,
    signAllSignatureFields,
    signAllFormFields,
    cleanAllSignatureFields,
    cleanAllFormFields,
    cleanChangedFields,
    signSignatureField,
    // getSignedWidgets,
    // getTotalWidgetsCount,
    getIsSignAllDisabled,
    zoomIndex,
    setSignaturePlaceholderOpacity,
    setFormFieldPlaceholderOpacity,
    setFormFieldDateIcon,
  } = useInstance();
  const recipientId = activeRecipient?.email || "";
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const [currentZoom, setCurrentZoom] = useState<number>(0);
  const [currentScrollLeft, setCurrentScrollLeft] = useState<number>(0);
  const [currentScrollTop, setCurrentScrollTop] = useState<number>(0);
  const [stateInstance, setStateInstance] = useState<Instance | null>(null);
  const [statePSPDFKit, setStatePSPDFKit] = useState<any>(null);
  const [stateChanged, setStateChanged] = useState<boolean>(false);
  // const [annotationsChanged, setAnnotationsChanged] = useState<boolean>(false);
  const [isFooter, setIsFooter] = useState<boolean>(false);
  const [isSigningStarted, setIsSigningStarted] = useState<boolean>(
    isOwner || storageisSigningStarted,
  );
  const [totalRequiredWidgets, setTotalRequiredWidgets] = useState<number>(0);
  const [totalOptionalWidgets, setTotalOptionalWidgets] = useState<number>(0);
  // const [totalWidgets, setTotalWidgets] = useState<number>(0);
  // const [totalSigned, setTotalSigned] = useState<number>(0);
  const [requiredSigned, setRequiredSigned] = useState<number>(0);
  const [optionalSigned, setOptionalSigned] = useState<number>(0);
  const [storedSignatures, setStoredSignatures] = useState<any[]>([]);
  const [storedAttachments, setStoredAttachments] = useState<any[]>([]);
  // const [storedSignaturesInitialized, setStoredSignaturesInitialized] =
  //   useState<boolean>(false);
  const [isSignAllDisabled, setIsSignAllDisabled] = useState<boolean>(false);
  const [isFieldsProcessing, setIsFieldsProcessing] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const isCreateInitial = useRef<boolean>(false);
  const isToDeleteSignature = useRef<any>(null);
  const debounceTimeout = useRef<any>(null);
  const debounceFormFieldTimeout = useRef<any>(null);
  const isSelectedTabType = useRef<boolean>(false);
  const isNameShowed = useRef<boolean>(false);

  const { handleAddWaterMark } = useUploadRequest();
  const isLocal = window.location.href.startsWith("http://localhost:");
  const { isMobile } = useContext(ThemeContext);
  const { inboxDocId, sentDocId, linkId, ownerLinkId } = useParams();
  const allSignatures = linkId ? allStoredGuestSignatures : allStoredSignatures;
  let docToSign = inboxDoc;
  if (sentDocId) {
    docToSign = sentDoc;
  } else if (linkId) {
    docToSign = guestRecipientDoc;
  } else if (ownerLinkId) {
    docToSign = guestOwnerDoc;
  }
  const guestName = docToSign?.recipients?.find((el) => el.color)?.name;
  const guestNameArray = guestName?.split(" ") || [];
  const guestFirstName = guestNameArray.length > 0 && guestNameArray[0];
  const guestLastName = guestNameArray.length > 1 && guestNameArray[1];

  const handleChangeSignature = useCallback(
    async (isInitial?: boolean) => {
      isCreateInitial.current = false;
      if (isInitial) {
        isCreateInitial.current = true;
      }
      isToDeleteSignature.current = true;
      // const storedSignatures = inboxDocId
      //   ? await getStoredSignatures(isCreateInitial.current)
      //   : [];
      const filteredList = statePSPDFKit.Immutable?.List(
        storedSignatures
          ?.filter((el) => el)
          ?.filter((el) =>
            isCreateInitial.current
              ? el?.customData?.isInitial
              : !el?.customData?.isInitial,
          )
          ?.map(statePSPDFKit.Annotations.fromSerializableObject),
      );
      stateInstance?.setStoredSignatures(filteredList);
      stateInstance?.setViewState((viewState: any) =>
        viewState.set(
          "interactionMode",
          statePSPDFKit.InteractionMode.SIGNATURE,
        ),
      );
    },
    [statePSPDFKit, stateInstance, storedSignatures],
  );

  // const initTotalSigned = useCallback(async () => {
  //   if (stateInstance) {
  //     const signedWidgets = await getSignedWidgets({
  //       instance: stateInstance,
  //     });

  //     setTotalSigned(signedWidgets || 0);
  //   }
  // }, [getSignedWidgets, stateInstance]);

  // const initSignatures = useCallback(async () => {
  //   if (stateInstance) {
  //     const newStoredSignatures = await getStoredSignatures(
  //       isCreateInitial?.current,
  //     );
  //     if (
  //       newStoredSignatures &&
  //       newStoredSignatures.length > 0 &&
  //       !storedSignaturesInitialized
  //     ) {
  //       setStoredSignatures(newStoredSignatures);
  //       setStoredSignaturesInitialized(true);
  //     }
  //   }
  // }, [
  //   // allSignatures.length,
  //   getStoredSignatures,
  //   stateInstance,
  //   isCreateInitial,
  //   storedSignaturesInitialized,
  // ]);

  // const initAtachments = useCallback(async () => {
  //   if (stateInstance) {
  //     const newStoredAttachments = await getStoredAtachments(
  //       isCreateInitial?.current,
  //     );
  //     if (
  //       newStoredAttachments &&
  //       newStoredAttachments.length > 0 &&
  //       !storedAttachmentsInitialized
  //     ) {
  //       setStoredAttachments(newStoredAttachments);
  //       await sleep(1000);
  //       setStoredAttachmentsInitialized(true);
  //     }
  //   }
  // }, [
  //   getStoredAtachments,
  //   stateInstance,
  //   isCreateInitial,
  //   storedAttachmentsInitialized,
  // ]);

  const initSignatures = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    setStoredSignatures(res);
  }, [allSignatures]);

  const initAtachments = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    let attachmentsArray = [];
    if (res && res?.length > 0) {
      attachmentsArray =
        res
          ?.filter((el) => el?.customData)
          .map((el) => {
            return {
              ...el?.customData?.atachment,
              isDefault: el?.customData?.isSignatureDefault,
            };
          }) || [];
    }
    // return attachmentsArray;

    setStoredAttachments(attachmentsArray);
  }, [allSignatures]);

  // const initTotalWidgets = useCallback(async () => {
  //   if (stateInstance) {
  //     const totalWidgetsCount = await getTotalWidgetsCount({
  //       instance: stateInstance,
  //       PSPDFKit: statePSPDFKit,
  //     });

  //     setTotalWidgets(totalWidgetsCount);
  //   }
  // }, [getTotalWidgetsCount, stateInstance, statePSPDFKit]);

  const initIsSignAllDisabled = useCallback(async () => {
    if (stateInstance) {
      const res = await getIsSignAllDisabled({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      stateInstance.setViewState((state) => state.set("zoom", 1.5));
      stateInstance.setViewState((state) => state.set("zoom", zoomIndex));

      setIsSignAllDisabled(res);
    }
  }, [getIsSignAllDisabled, stateInstance, statePSPDFKit, zoomIndex]);

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsSignaturesToSignCount = 0;
      let widgetsInitialsToSignCount = 0;
      let widgetsDateCount = 0;
      let widgetsCheckboxCount = 0;
      let widgetsTextCount = 0;

      let signaturesCount = 0;
      let initialsCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());
      const allFormFieldValues = stateInstance.getFormFieldValues();

      try {
        if (pageIndexes.length > 0 && statePSPDFKit && stateInstance) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations =
                  stateInstance.getAnnotations &&
                  (await stateInstance.getAnnotations(page));

                const widgetsSignaturesToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );
                const widgetsInitialsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_INITIALS"),
                );
                const widgetsDate = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("DATE_WIDGET"),
                );
                const widgetsCheckbox = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("CHECKBOX_WIDGET"),
                );
                const widgetsText = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("TEXT_WIDGET"),
                );

                const signatures = allAnnotations.filter((el: Annotation) => {
                  return (
                    el.isSignature &&
                    !el.customData?.isInitial &&
                    !el.creatorName
                  );
                });
                const initials = allAnnotations.filter(
                  (el: Annotation) =>
                    el.isSignature &&
                    el.customData?.isInitial &&
                    !el.creatorName,
                );

                // Increment counts
                widgetsSignaturesToSignCount += widgetsSignaturesToSign.size;
                widgetsInitialsToSignCount += widgetsInitialsToSign.size;
                widgetsDateCount += widgetsDate.size;
                widgetsCheckboxCount += widgetsCheckbox.size;
                widgetsTextCount += widgetsText.size;

                signaturesCount += signatures.size;
                initialsCount += initials.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }

      await sleep(500);

      const savedJSON = getSavedJSON();
      const checkboxesCount =
        allFormFieldValues && savedJSON?.formFieldValues
          ? savedJSON.formFieldValues
              ?.filter((el: any) => el.name?.startsWith("CHECKBOX_WIDGET"))
              .filter((el: any) => el.value && el.value[0] !== "Off")?.length
          : 0;
      // const checkboxesCount = allFormFieldValues
      //   ? Object?.entries(allFormFieldValues)
      //       ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
      //       .filter((el) => el[1] && el[1][0] !== "Off")?.length
      //   : 0;
      const datesCount = allFormFieldValues
        ? Object?.entries(allFormFieldValues)
            ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
            .filter((el) => el[1] && el[1][0] !== "")?.length
        : 0;
      const allTextWidgets = allFormFieldValues
        ? Object?.entries(allFormFieldValues)?.filter((el: any) =>
            el[0].startsWith("TEXT_WIDGET"),
          )
        : 0;
      const textCount = allTextWidgets
        ? allTextWidgets?.filter((el: any) => el[1] && el[1][0] !== "")?.length
        : 0;

      setRequiredSigned(signaturesCount + initialsCount + datesCount);
      setOptionalSigned(checkboxesCount + textCount);
      setTotalRequiredWidgets(
        widgetsSignaturesToSignCount +
          widgetsInitialsToSignCount +
          widgetsDateCount,
      );
      setTotalOptionalWidgets(widgetsCheckboxCount + widgetsTextCount);

      // setTotalSigned(
      //   signaturesCount +
      //     initialsCount +
      //     checkboxesCount +
      //     datesCount +
      //     textCount,
      // );
      // setTotalWidgets(
      //   widgetsSignaturesToSignCount +
      //     widgetsInitialsToSignCount +
      //     widgetsDateCount +
      //     widgetsCheckboxCount +
      //     widgetsTextCount,
      // );
    }
  }, [stateInstance, statePSPDFKit, getSavedJSON]);

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures, stateChanged]);

  useEffect(() => {
    const page =
      stateInstance?.contentDocument?.querySelector(".PSPDFKit-Scroll");
    if (page && isFooter) {
      page.scrollTop = page.scrollHeight;
    }
  }, [isFooter, stateInstance]);

  const handleSignAll = useCallback(async () => {
    if (stateInstance) {
      await Promise.all([
        signAllSignatureFields({
          PSPDFKit: statePSPDFKit,
          instance: stateInstance,
        }),
        signAllFormFields({
          PSPDFKit: statePSPDFKit,
          instance: stateInstance,
        }),
      ]);
    }
  }, [signAllFormFields, signAllSignatureFields, stateInstance, statePSPDFKit]);

  const handleCleanAll = useCallback(async () => {
    if (stateInstance) {
      await Promise.all([
        cleanAllFormFields({
          instance: stateInstance,
          PSPDFKit: statePSPDFKit,
        }),
        cleanAllSignatureFields({
          instance: stateInstance,
          PSPDFKit: statePSPDFKit,
        }),
      ]);
    }
  }, [
    cleanAllFormFields,
    cleanAllSignatureFields,
    stateInstance,
    statePSPDFKit,
  ]);

  const handleDownloadFile = useCallback(async () => {
    let res;
    if (docToSign) {
      if (linkId) {
        res = await SharedDocuments.downloadGuestRecipientDoc({
          id: linkId,
        });
      } else if (ownerLinkId) {
        res = await SharedDocuments.downloadGuestOwnerDoc({
          id: ownerLinkId,
        });
      } else if (sentDocId) {
        res = await SharedDocuments.downloadSentDoc({
          id: sentDocId,
          isBackupFile: isBackup,
        });
      } else {
        res = await SharedDocuments.downloadInboxDoc({
          id: docToSign.id,
          isBackupFile: isBackup,
        });
      }
    }
    if (res?.file?.url && docToSign) {
      axios
        .get(res?.file?.url, {
          responseType: "blob",
        })
        .then(async (res) => {
          if (
            linkId ||
            ownerLinkId ||
            (user?.isPremium && user?.isWebPremium)
          ) {
            fileDownload(res.data, `${docToSign?.name}.pdf`);
          } else {
            const newFileData = await toArrayBuffer(res.data);
            const updatedBuffer =
              newFileData &&
              (await handleAddWaterMark(newFileData as ArrayBuffer));
            const blob =
              updatedBuffer &&
              new Blob([updatedBuffer], {
                type: "application/pdf",
              });
            blob && fileDownload(blob, `${docToSign?.name}.pdf`);
          }
        });
    }
  }, [
    docToSign,
    handleAddWaterMark,
    linkId,
    ownerLinkId,
    sentDocId,
    user,
    isBackup,
  ]);

  // useEffect(() => {
  //   initTotalWidgets();
  // }, [initTotalWidgets, isFooter]);

  // useEffect(() => {
  //   initTotalSigned();
  // }, [initTotalSigned, isFooter]);

  useEffect(() => {
    if (!user && (inboxDocId || sentDocId)) {
      dispatch(getUser());
    }
  }, [dispatch, inboxDocId, sentDocId, user]);

  useEffect(() => {
    initSignatures();
  }, [initSignatures]);

  useEffect(() => {
    initAtachments();
  }, [initAtachments]);

  useEffect(() => {
    initIsSignAllDisabled();
  }, [initIsSignAllDisabled, stateChanged]);

  useEffect(() => {
    // const savedJSON = getSavedJSON();
    // if (!savedJSON) {
    const savedEditedJSON = docToSign?.instantJson
      ? {
          ...docToSign?.instantJson,
          ...(docToSign?.instantJson.annotations?.length > 0 && {
            annotations: docToSign?.instantJson?.annotations.map(
              (item: any) => ({
                ...item,
              }),
            ),
          }),
        }
      : null;

    const dataStringified = savedEditedJSON && JSON.stringify(savedEditedJSON);
    localStorage.setItem("instantJSON", dataStringified);
    // }
  }, [docToSign?.instantJson, inboxDocId, linkId, sentDocId, ownerLinkId]);

  useEffect(() => {
    if (stateInstance && statePSPDFKit) {
      stateInstance.addEventListener(
        "storedSignatures.create",
        async (annotation: Annotation) => {
          // isToDeleteSignature.current = true;
          if (annotation instanceof statePSPDFKit.Annotations.InkAnnotation) {
            await stateInstance.create(annotation);
            // saveInkAnnotationAtachment({ annotation, instance });
          }
          // if (annotation.imageAttachmentId) {
          //   await saveImageAnnotationAtachment({ annotation, instance });
          //   sessionStorage.setItem("toDeleteSignature", "true");
          // }
          await saveSignatures({
            annotation,
            instance: stateInstance,
            PSPDFKit: statePSPDFKit,
            isInitial: isCreateInitial.current,
          });
          await cleanChangedFields({
            instance: stateInstance,
            isInitial: isCreateInitial.current,
          });
          // await sleep(500);
          // setStoredSignaturesInitialized(false);
        },
      );

      stateInstance.addEventListener(
        "storedSignatures.delete",
        async (annotation: any) => {
          await deleteSignature({
            annotation,
            instance: stateInstance,
            PSPDFKit: statePSPDFKit,
            userId: user?.id,
            isInitial: isCreateInitial.current,
          });
          await cleanChangedFields({
            instance: stateInstance,
            isInitial: isCreateInitial.current,
          });
          stateInstance.setViewState((viewState) =>
            viewState.set("interactionMode", null),
          );
          // await sleep(500);
          // setStoredSignaturesInitialized(false);
        },
      );
    }
  }, [
    cleanChangedFields,
    deleteSignature,
    saveSignatures,
    stateInstance,
    statePSPDFKit,
    user,
  ]);

  const setInitialSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const filteredAttachments = storedAttachments?.filter((el) => el.id);
      const filteredStoredSignatures = storedSignatures
        ?.filter((el: any) => el)
        .filter((el) =>
          isCreateInitial.current
            ? el?.customData?.isInitial
            : !el?.customData?.isInitial,
        );
      const list =
        filteredStoredSignatures &&
        filteredStoredSignatures?.length > 0 &&
        filteredStoredSignatures[0].type
          ? statePSPDFKit.Immutable?.List(
              filteredStoredSignatures?.map(
                statePSPDFKit.Annotations.fromSerializableObject,
              ),
            )
          : [];

      if (filteredAttachments?.length > 0 && filteredAttachments[0]?.url) {
        const blobs = await Promise.all(
          filteredAttachments.map(({ url }: any) =>
            fetch(url).then((res) => res.blob()),
          ),
        );
        blobs.forEach(stateInstance.createAttachment);
      }

      list?.size && stateInstance.setStoredSignatures(list);
    }
  }, [stateInstance, statePSPDFKit, storedAttachments, storedSignatures]);

  useEffect(() => {
    setInitialSignatures();
  }, [setInitialSignatures]);

  const setAnnotationsListeners = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      stateInstance.addEventListener(
        "annotations.create",
        async (createdAnnotations) => {
          const annotation = createdAnnotations.get(0);
          const toSaveSignature = sessionStorage.getItem("toSaveSignature");
          await setSignaturePlaceholderOpacity({ instance: stateInstance });

          if (
            annotation &&
            annotation instanceof statePSPDFKit.Annotations.InkAnnotation &&
            toSaveSignature !== "false"
          ) {
            await saveInkAnnotationAtachment({
              annotation,
              instance: stateInstance,
              isInitial: annotation.customData
                ? (annotation.customData.isInitial as boolean)
                : isCreateInitial.current,
            });
          }
          // if (
          //   annotation &&
          //   annotation instanceof statePSPDFKit.Annotations.ImageAnnotation
          // ) {
          //   await sleep(500);
          // }
          // if (isToDeleteSignature.current && annotation) {
          //   await stateInstance.delete(annotation.id);
          //   // await sleep(500);
          //   // isToDeleteSignature.current = false;
          // }
          const overlappingAnnotations =
            annotation &&
            (await stateInstance.getOverlappingAnnotations(annotation));
          const overlappingAnnotation = overlappingAnnotations?.get(0);
          const isNotOverlappingAnnotationSignature = overlappingAnnotation
            ? !overlappingAnnotation.formFieldName?.startsWith("SIGNATURE")
            : true;
          if (
            annotation &&
            ((annotation.boundingBox?.top === 0 &&
              annotation.boundingBox?.left === 0 &&
              isNotOverlappingAnnotationSignature) ||
              isToDeleteSignature.current)
          ) {
            await stateInstance.delete(annotation.id);
          }
          if (annotation?.customData?.savedSignatureId && stateInstance) {
            const dataToSave = {
              id: annotation.customData.savedSignatureId as string,
              data: {
                isDefault: true,
              },
            };

            linkId
              ? await Signatures.putGuestSignature({
                  docId: linkId,
                  data: dataToSave,
                })
              : await Signatures.putSignature(dataToSave);

            await cleanAllSignatureFields({
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
              isInitial: isCreateInitial.current,
            });
            dispatch(setIsUpdated(true));
          }
        },
      );

      stateInstance.addEventListener("annotations.focus", async (event) => {
        const { annotation } = event;
        isCreateInitial.current = !!annotation?.customData?.isInitial;
      });

      stateInstance.addEventListener("annotations.didSave", async () => {
        saveInstanceJSON({ instance: stateInstance, recipientId });

        const annotId = sessionStorage.getItem("created_annotation_id");
        const formFields = await stateInstance.getFormFields();
        // Get a signature form with the specific name you want.
        const field =
          annotId &&
          formFields.find((formField) => formField.name.includes(annotId));

        if (field) {
          const res = await stateInstance.getOverlappingAnnotations(field);
          const newAnnotation = res.get(0);

          newAnnotation?.id &&
            sessionStorage.setItem("annotation_id", newAnnotation.id);
          sessionStorage.removeItem("created_annotation_id");
        }
      });

      const debouncedHandler = (event: any) => {
        clearTimeout(debounceFormFieldTimeout.current);
        setIsFieldsProcessing(true);
        debounceFormFieldTimeout.current = setTimeout(async () => {
          const isPUpdated = sessionStorage.getItem("placeholdersUpdated");
          saveInstanceJSON({ instance: stateInstance, recipientId });
          if (!isPUpdated) {
            await setFormFieldPlaceholderOpacity({
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
            });
            await setFormFieldDateIcon({
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
            });
          }
          setIsFieldsProcessing(false);
        }, 2000);
      };

      stateInstance.addEventListener(
        "formFieldValues.didSave",
        debouncedHandler,
      );

      stateInstance.addEventListener("formFieldValues.willSave", () => {
        sessionStorage.removeItem("placeholdersUpdated");
      });

      stateInstance.addEventListener(
        "annotations.delete",
        async (deletedAnnotations) => {
          await setSignaturePlaceholderOpacity({ instance: stateInstance });
          await sleep(300);
          isToDeleteSignature.current = false;
        },
      );

      return () => {
        stateInstance.removeEventListener(
          "formFieldValues.didSave",
          debouncedHandler,
        );
        clearTimeout(debounceFormFieldTimeout.current);
      };
    }
  }, [
    stateInstance,
    statePSPDFKit,
    cleanAllSignatureFields,
    dispatch,
    linkId,
    recipientId,
    saveInkAnnotationAtachment,
    saveInstanceJSON,
    setSignaturePlaceholderOpacity,
    setFormFieldPlaceholderOpacity,
    setFormFieldDateIcon,
  ]);

  useEffect(() => {
    setAnnotationsListeners();
  }, [setAnnotationsListeners]);

  const activateFields = useCallback(() => {
    if (isSigningStarted) {
      const innerDoc =
        stateInstance?.contentDocument ||
        stateInstance?.contentWindow?.document;
      const readOnlyInputs = innerDoc?.querySelectorAll("input");
      const readOnlyText = innerDoc?.querySelectorAll<HTMLElement>(
        ".PSPDFKit-Annotation-Widget-Text",
      );
      const readOnlyCheckboxes = innerDoc?.querySelectorAll<HTMLElement>(
        ".PSPDFKit-Annotation-Widget-CheckBox",
      );
      const readOnlySignatures = innerDoc?.querySelectorAll<HTMLElement>(
        ".PSPDFKit-Annotation-Widget-Signature",
      );
      readOnlyInputs?.forEach((item) => item.removeAttribute("disabled"));

      if (readOnlyText && readOnlyCheckboxes && readOnlySignatures) {
        [
          ...readOnlyText,
          ...readOnlyCheckboxes,
          ...readOnlySignatures,
        ]?.forEach((item) => {
          item.style.pointerEvents = "auto";
          return "";
        });
      }
    }
  }, [isSigningStarted, stateInstance]);

  const setAnnotationsPress = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const debouncedHandler = (event: any) => {
        if (event.selected) {
          event.preventDefault();
        }
        const { annotation } = event;
        if (annotation.id?.length < 6) {
          stateInstance.update(annotation.set("readOnly", true));
          return;
        }
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(async () => {
          if (!isSigningStarted) {
            return;
          }
          isCreateInitial.current = !!annotation?.customData?.isInitial;
          sessionStorage.removeItem("toSaveSignature");

          if (isCreateInitial.current && annotation) {
            sessionStorage.setItem("created_annotation_id", annotation.id);
          }

          if (annotation.formFieldName?.startsWith("SIGNATURE_INITIALS")) {
            pushGaEvent({
              name: "Add Initials",
              action: "Applyied initials",
            });
          } else if (annotation.formFieldName?.startsWith("SIGNATURE")) {
            pushGaEvent({
              name: "Add graphical signature",
              action: "Successfull applying of digital signature",
            });
          } else if (annotation.formFieldName?.startsWith("CHECKBOX")) {
            pushGaEvent({
              name: "Add checkbox",
              action: "Tick the checkbox",
            });
          } else if (annotation.formFieldName?.startsWith("TEXT")) {
            pushGaEvent({
              name: "Add annotation",
              action: "Applied annotations (text)",
            });
          } else if (annotation.formFieldName?.startsWith("DATE_WIDGET")) {
            pushGaEvent({
              name: "Add date",
              action: "Applied date",
            });
          }

          if (annotation.formFieldName?.startsWith("SIGNATURE_INITIALS")) {
            isCreateInitial.current = true;
          }
          const filteredStoredSignatures = storedSignatures?.filter((el) => el);
          const filteredList =
            filteredStoredSignatures &&
            statePSPDFKit.Immutable.List(
              filteredStoredSignatures
                ?.filter((el) =>
                  isCreateInitial.current
                    ? el?.customData?.isInitial
                    : !el?.customData?.isInitial,
                )
                ?.map(statePSPDFKit.Annotations.fromSerializableObject),
            );

          filteredList && stateInstance.setStoredSignatures(filteredList);
          const instSignatures = await stateInstance
            .getStoredSignatures()
            .then((signatures) =>
              signatures
                .map(statePSPDFKit.Annotations.toSerializableObject)
                .toJS(),
            );
          const isDefaultSignature = instSignatures.some(
            (el) =>
              el?.customData?.isSignatureDefault &&
              (isCreateInitial.current
                ? el?.customData?.isInitial
                : !el?.customData?.isInitial),
          );
          event.preventDefault &&
            annotation.formFieldName?.startsWith("SIGNATURE") &&
            instSignatures?.length > 0 &&
            event.preventDefault();

          activateFields();
          if (
            instSignatures?.length > 0 &&
            !annotation.isSignature &&
            annotation.formFieldName?.startsWith("SIGNATURE")
          ) {
            signSignatureField({
              annotation,
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
              isInitial: isCreateInitial.current,
            });
          }

          if (
            instSignatures?.length > 0 &&
            isDefaultSignature &&
            annotation.formFieldName?.startsWith("SIGNATURE")
          ) {
            stateInstance.setViewState((viewState) =>
              viewState.set("interactionMode", null),
            );
          }
        }, 300);
      };

      stateInstance.addEventListener("annotations.press", debouncedHandler);

      stateInstance.addEventListener("annotations.willChange", (event) => {
        const editedAnnotation = event.annotations && event.annotations.get(0);
        if (
          event.reason.startsWith("MOVE_END") &&
          editedAnnotation?.id &&
          editedAnnotation.id?.length < 6
        ) {
          stateInstance.history.undo();
        }
      });

      return () => {
        stateInstance.removeEventListener(
          "annotations.press",
          debouncedHandler,
        );
        clearTimeout(debounceTimeout.current);
      };
    }
  }, [
    stateInstance,
    statePSPDFKit,
    isSigningStarted,
    signSignatureField,
    storedSignatures,
    activateFields,
  ]);

  useEffect(() => {
    setAnnotationsPress();
  }, [setAnnotationsPress]);

  const findTabInstance = useCallback(
    ({ instance }: { instance: Instance }) => {
      const tabsInstance = instance?.contentDocument.querySelectorAll(
        ".BaselineUI-Tabs-TabButton",
      );
      if (!isSelectedTabType.current && tabsInstance) {
        const typeTabElem = tabsInstance && (tabsInstance[2] as HTMLElement);
        typeTabElem?.click();
        isSelectedTabType.current = true;
      }
      if (!tabsInstance || tabsInstance?.length === 0) {
        isSelectedTabType.current = false;
        isNameShowed.current = false;
      }
    },
    [isSelectedTabType],
  );

  useEffect(() => {
    if (stateInstance) {
      const interval = setInterval(() => {
        findTabInstance({ instance: stateInstance });
        return () => clearInterval(interval);
      }, 300);
      if (isSelectedTabType.current) {
        clearInterval(interval);
      }
    }
  }, [stateInstance, findTabInstance]);

  const handleCheckGuestUser = useCallback(async () => {
    if (isMobile) return;
    if ((linkId || ownerLinkId) && authUser?.id && docToSign) {
      const res = await SharedDocuments.checkGuestUser(
        linkId || ownerLinkId || "",
        {
          userId: authUser?.id,
        },
      );

      if (res?.document?.isTrash && res?.document?.type === "MY_DOCS") {
        navigate(`${PATHES.TRASH_DOCUMENT}/certified/${res?.document?.id}`);
        return;
      }

      if (!res?.document?.isTrash && res?.document?.type === "INBOX") {
        navigate(`${PATHES.INBOX_DOCUMENT}/${res?.document?.id}`);
        return;
      }

      if (!res?.document?.isTrash && res?.document?.type === "SENT") {
        navigate(`${PATHES.SENT_DOCUMENT}/${res?.document?.id}`);
        return;
      }

      if (!res?.document?.isTrash && res?.document?.type === "MY_DOCS") {
        navigate(`${PATHES.DETAILED_VIEW}/certified/${res?.document?.id}`);
      }
    }
  }, [docToSign, authUser, navigate, linkId, ownerLinkId, isMobile]);

  useEffect(() => {
    handleCheckGuestUser();
  }, [handleCheckGuestUser]);

  useEffect(() => {
    if (docToSign?.file?.url) {
      const container = containerRef.current;

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");
        // setPSPDFKit(PSPDFKit);

        if (container) {
          const savedJSON = getSavedJSON();
          // const storedSignatures = await getStoredSignatures(
          //   isCreateInitial?.current,
          // );
          // const storedAttachments = await getStoredAtachments();
          // const filteredStoredSignatures = storedSignatures?.filter(
          //   (el: any) => el,
          // );

          const initialViewState = new PSPDFKit.ViewState({
            scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
            layoutMode: PSPDFKit.LayoutMode.SINGLE,
            pageSpacing: 10,
            spreadSpacing: 50,
            viewportPadding: { horizontal: isMobile ? 20 : 200, vertical: 20 },
          });

          const emptyButton = {
            type: "custom",
            id: "emptyButton",
            node: getEmptyNode(5, `1px solid ${palette.grey30}`),
          };

          // const signAllButton = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: getButtonNode("Sign all boxes", signIcon, "#F1F3F9"),
          //   onPress: async () => {
          //     if (instance && PSPDFKit) {
          //       await signAllSignatureFields({
          //         PSPDFKit,
          //         instance,
          //       });
          //     }
          //     await signAllFormFields({
          //       PSPDFKit,
          //       instance,
          //     });
          //   },
          //   disabled: isSignAllDisabled,
          // };

          // const clearAllButton = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: getButtonNode("Clear all boxes", eraseIcon, "#F1F3F9"),
          //   onPress: async () => {
          //     if (instance) {
          //       await cleanAllSignatureFields({ instance, PSPDFKit });
          //     }
          //   },
          //   disabled: totalSigned === 0,
          // };

          // const signedStatus = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: signedStatusNode(totalSigned, totalWidgets),
          // };

          const moveButton = {
            type: "custom",
            id: "moveToFolder",
            title: "Move to another folder",
            icon: moveFolderIcon,
            onPress: async () => {
              inboxDocId && handleMoveInboxDocument();
              sentDocId && handleMoveSentDocument();
            },
          };

          const exportButton = {
            type: "custom",
            id: "export",
            icon: downloadIcon,
            onPress: handleDownloadFile,
          };

          const printButton = {
            type: "custom",
            id: "print",
            icon: printIcon,
            onPress: async () => {
              instance.print({ excludeAnnotations: true });
            },
          };

          const toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            // isSigningStarted && !isMobile && signAllButton,
            // isSigningStarted && !isMobile && clearAllButton,
            // isSigningStarted && !isMobile && signedStatus,
            { type: "spacer" },
            { type: "search" },
            exportButton,
            printButton,
          ].filter(Boolean);
          const mobileItems =
            docToSign.status === "COMPLETED"
              ? [
                  { type: "search" },
                  { type: "spacer" },
                  exportButton,
                  printButton,
                ]
              : [];

          // const list =
          //   filteredStoredSignatures &&
          //   filteredStoredSignatures?.length > 0 &&
          //   filteredStoredSignatures[0].type
          //     ? PSPDFKit.Immutable?.List(
          //         filteredStoredSignatures
          //           // ?.filter((el) =>
          //           //   !isCreateInitial.current
          //           //     ? !el?.customData?.isInitial
          //           //     : true,
          //           // )
          //           ?.map(PSPDFKit.Annotations.fromSerializableObject),
          //       )
          //     : [];
          // const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
          // const attachmentsArray =
          //   attachmentsString && JSON.parse(attachmentsString);
          // const attachmentsArray =
          //   storedSignatures
          //     ?.filter((el) => el.customData?.atachment)
          //     .map((el) => el.customData?.atachment) || [];

          docToSign.status === "COMPLETED" &&
            (inboxDoc || sentDocId) &&
            !isBackup &&
            toolbarItems.push(moveButton);
          !isMobile && toolbarItems.push(emptyButton);

          PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.
          setStateInstance(null);
          setStatePSPDFKit(null);

          const activateFields = () => {
            const innerDoc =
              instance?.contentDocument || instance?.contentWindow?.document;
            const ownerSignatures = innerDoc?.querySelectorAll<HTMLElement>(
              ".PSPDFKit-Smooth-Lines",
            );
            ownerSignatures?.forEach((item) => {
              item.setAttribute("data-non-clickable", "true");
              return "";
            });
            if (isSigningStarted) {
              const readOnlyInputs = innerDoc?.querySelectorAll("input");
              const readOnlyText = innerDoc?.querySelectorAll<HTMLElement>(
                ".PSPDFKit-Annotation-Widget-Text",
              );
              const readOnlyCheckboxes =
                innerDoc?.querySelectorAll<HTMLElement>(
                  ".PSPDFKit-Annotation-Widget-CheckBox",
                );
              const readOnlySignatures =
                innerDoc?.querySelectorAll<HTMLElement>(
                  ".PSPDFKit-Annotation-Widget-Signature",
                );
              // const signatureFigures =
              //   innerDoc?.querySelectorAll<HTMLElement>("figure");

              // signatureFigures?.forEach((item) => {
              //   item.style.pointerEvents = "auto";
              //   return "";
              // });

              readOnlyInputs?.forEach((item) =>
                item.removeAttribute("disabled"),
              );

              [
                ...readOnlyText,
                ...readOnlyCheckboxes,
                ...readOnlySignatures,
              ]?.forEach((item) => {
                item.style.pointerEvents = "auto";
                return "";
              });
            }
          };

          const {
            UI: { createBlock, Recipes, Interfaces },
          } = PSPDFKit;

          const configuration = {
            container,
            document: docToSign.file.url,
            baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
            initialViewState,
            toolbarItems: isMobile ? mobileItems : toolbarItems,
            enableClipboardActions: true,
            enableHistory: true,
            locale: "en",
            styleSheets: ["/custom-pspdfkit.css"],
            maxDefaultZoomLevel: 2,
            minDefaultZoomLevel: 0.5,
            dynamicFonts: `${window.location.origin}/dynamicFonts/fonts.json`,
            licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
            ...(savedJSON && {
              instantJSON: savedJSON,
            }),
            annotationTooltipCallback: (annotation: any) => {
              if (annotation.isSignature && annotation.id?.length > 6) {
                const toolItem = {
                  type: "custom",
                  title: t("clear"),
                  id: "item-text-tooltip-annotation",
                  className: "TooltipItem-Text",
                  onPress: () => {
                    instance.delete(annotation.id);
                  },
                };

                return [toolItem];
              }
              return [];
            },
            customRenderers: {
              Annotation: ({ annotation }: any) => {
                const placeholderEl = document.createElement("div");
                const calendarEl = document.createElement("div");
                const imgEl = document.createElement("img");
                imgEl.src = dateIcon;
                imgEl.width = 16;
                imgEl.height = 16;
                if (annotation.formFieldName?.startsWith("SIGNATURE")) {
                  placeholderEl.style.fontSize = "24px";
                  placeholderEl.style.height = "100%";
                  placeholderEl.style.display = "flex";
                  placeholderEl.style.justifyContent = "center";
                  placeholderEl.style.alignItems = "center";
                  if (annotation.opacity !== 0) {
                    placeholderEl.innerHTML = annotation.customData?.isInitial
                      ? "Initials"
                      : "Sign";
                  }
                }
                if (annotation.formFieldName?.startsWith("DATE")) {
                  calendarEl.setAttribute(
                    "data-calendar-icon",
                    annotation.formFieldName,
                  );
                  calendarEl.style.width = "20px";
                  calendarEl.style.height = "20px";
                  calendarEl.style.position = "absolute";
                  calendarEl.style.right = "2px";
                  calendarEl.style.top = "50%";
                  calendarEl.style.opacity = "0.7";
                  calendarEl.style.transform = "translateY(-50%)";
                  if (annotation.backgroundColor) {
                    calendarEl.appendChild(imgEl);
                  }
                }
                const parentDiv = document.createElement("div");
                parentDiv.style.height = "100%";
                parentDiv.appendChild(placeholderEl);
                parentDiv.appendChild(calendarEl);
                return {
                  node: parentDiv,
                  append: true,
                };
              },
            },
            ui: {
              [Interfaces.CreateSignature]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.CreateSignature,
                    props,
                    ({ ui }: any) => {
                      const userFirstName = linkId
                        ? guestFirstName
                        : user?.firstName;
                      const userLastName = linkId
                        ? guestLastName
                        : user?.lastName;
                      const initialsText =
                        userFirstName && userLastName
                          ? `${userFirstName[0]}. ${userLastName[0]}.`
                          : "";
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Create Initial";
                        ui.getBlockById(
                          "save-signature-checkbox",
                        )._props.label = "Save Initial";

                        const textInput = ui.getBlockById(
                          "signature-text-input",
                        );
                        if (textInput && !isNameShowed.current) {
                          textInput._props.placeholder = "Initial";
                          textInput._props.value = initialsText;
                          textInput._props.label = "Initial here";
                          textInput._props.clearLabel = "Clear initial";
                          initialsText &&
                            textInput._props.onChange(initialsText);
                          isNameShowed.current = true;

                          const freehand = ui.getBlockById("freehand-canvas");
                          freehand._props.placeholder = "Initial here";
                          freehand._props.clearLabel = "Clear initial";

                          const fontselect = ui.getBlockById("font-selector");
                          if (
                            fontselect._props.items[0].label === "Signature"
                          ) {
                            fontselect._props.items =
                              fontselect._props.items.map((item: any) => {
                                return {
                                  id: item.id,
                                  label: initialsText || "Initial",
                                };
                              });
                          }
                        }
                      } else {
                        const textInput = ui.getBlockById(
                          "signature-text-input",
                        );
                        const signatureText =
                          userFirstName && userLastName
                            ? `${userFirstName} ${userLastName}`
                            : "";
                        if (textInput && !isNameShowed.current) {
                          textInput._props.value = signatureText;
                          signatureText &&
                            textInput._props.onChange(signatureText);
                          isNameShowed.current = true;

                          const fontselect = ui.getBlockById("font-selector");
                          if (
                            fontselect._props.items[0].label === "Signature"
                          ) {
                            fontselect._props.items =
                              fontselect._props.items.map((item: any) => {
                                return {
                                  id: item.id,
                                  label: signatureText || "Signature",
                                };
                              });
                          }
                        }
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
              [Interfaces.SignaturesList]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.SignaturesList,
                    props,
                    ({ ui }: any) => {
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Initials";
                        ui.getBlockById("add-signature")._props.label =
                          "Add initial";
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
            },
            renderPageCallback() {
              activateFields();
            },
          };

          PSPDFKit.preloadWorker(configuration);
          instance = await PSPDFKit.load(configuration);

          setStateInstance(instance);
          setStatePSPDFKit(PSPDFKit);

          instance.setViewState((viewState) => {
            return viewState.set(
              "sidebarMode",
              isMobile ? null : PSPDFKit.SidebarMode.THUMBNAILS,
            );
          });

          instance.addEventListener(
            "document.saveStateChange",
            async (event) => {
              if (event.hasUnsavedChanges) {
                dispatch(setStoredInstance(instance));
              }
              setStateChanged((prevState) => !prevState);
            },
          );

          // instance.addEventListener("annotations.didSave", async () => {
          //   saveInstanceJSON({ instance, recipientId });

          //   const annotId = sessionStorage.getItem("created_annotation_id");
          //   const formFields = await instance.getFormFields();

          //   // Get a signature form with the specific name you want.
          //   const field =
          //     annotId &&
          //     formFields.find((formField) => formField.name.includes(annotId));

          //   if (field) {
          //     const res = await instance.getOverlappingAnnotations(field);
          //     const newAnnotation = res.get(0);

          //     newAnnotation?.id &&
          //       sessionStorage.setItem("annotation_id", newAnnotation.id);
          //     sessionStorage.removeItem("created_annotation_id");
          //   }

          //   // await sleep(1000);
          //   // setAnnotationsChanged((prevState) => !prevState);
          //   // isAnnotationsChanged.current = !isAnnotationsChanged.current;
          // });

          // instance.addEventListener("formFieldValues.didSave", async () => {
          //   saveInstanceJSON({ instance, recipientId });
          //   // setAnnotationsChanged((prevState) => !prevState);
          //   // isAnnotationsChanged.current = !isAnnotationsChanged.current;
          // });

          // instance.addEventListener("annotations.press", async (event) => {
          //   const { annotation } = event;
          //   if (!isSigningStarted) {
          //     return;
          //   }
          //   isCreateInitial.current = !!annotation?.customData?.isInitial;
          //   sessionStorage.removeItem("toSaveSignature");

          //   if (isCreateInitial.current && annotation) {
          //     sessionStorage.setItem("created_annotation_id", annotation.id);
          //   }

          //   if (annotation.formFieldName?.startsWith("SIGNATURE_INITIALS")) {
          //     isCreateInitial.current = true;
          //   }
          //   const filteredStoredSignatures = storedSignatures?.filter(
          //     (el: any) => el,
          //   );
          //   const filteredList =
          //     filteredStoredSignatures &&
          //     PSPDFKit.Immutable.List(
          //       filteredStoredSignatures
          //         ?.filter((el) =>
          //           isCreateInitial.current
          //             ? el?.customData?.isInitial
          //             : !el?.customData?.isInitial,
          //         )
          //         ?.map(PSPDFKit.Annotations.fromSerializableObject),
          //     );

          //   filteredList && instance.setStoredSignatures(filteredList);
          //   const instSignatures = await instance
          //     .getStoredSignatures()
          //     .then((signatures) =>
          //       signatures
          //         .map(PSPDFKit.Annotations.toSerializableObject)
          //         .toJS(),
          //     );
          //   const isDefaultSignature = instSignatures.some(
          //     (el) =>
          //       el?.customData?.isSignatureDefault &&
          //       (isCreateInitial.current
          //         ? el?.customData?.isInitial
          //         : !el?.customData?.isInitial),
          //   );
          //   event.preventDefault &&
          //     annotation.formFieldName?.startsWith("SIGNATURE") &&
          //     instSignatures?.length > 0 &&
          //     event.preventDefault();

          //   activateFields();
          //   if (
          //     instSignatures?.length > 0 &&
          //     !annotation.isSignature &&
          //     annotation.formFieldName?.startsWith("SIGNATURE")
          //   ) {
          //     signSignatureField({
          //       annotation,
          //       instance,
          //       PSPDFKit,
          //       isInitial: isCreateInitial.current,
          //     });
          //   }

          //   if (
          //     instSignatures?.length > 0 &&
          //     isDefaultSignature &&
          //     annotation.formFieldName?.startsWith("SIGNATURE")
          //   ) {
          //     instance.setViewState((viewState) =>
          //       viewState.set("interactionMode", null),
          //     );
          //   }
          // });

          // const filteredAttachments = storedAttachments?.filter((el) => el.id);

          // if (filteredAttachments?.length > 0 && filteredAttachments[0]?.url) {
          //   const blobs = await Promise.all(
          //     filteredAttachments.map(({ url }: any) =>
          //       fetch(url).then((res) => res.blob()),
          //     ),
          //   );
          //   blobs.forEach(instance.createAttachment);
          // }

          // list?.size && instance.setStoredSignatures(list);

          // instance.addEventListener(
          //   "storedSignatures.create",
          //   async (annotation: Annotation) => {
          //     isToDeleteSignature.current = true;
          //     if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
          //       await instance.create(annotation);
          //       // saveInkAnnotationAtachment({ annotation, instance });
          //     }
          //     // if (annotation.imageAttachmentId) {
          //     //   await saveImageAnnotationAtachment({ annotation, instance });
          //     //   sessionStorage.setItem("toDeleteSignature", "true");
          //     // }
          //     await saveSignatures({
          //       annotation,
          //       instance,
          //       PSPDFKit,
          //       isInitial: isCreateInitial.current,
          //     });
          //     await cleanChangedFields({
          //       instance,
          //       isInitial: isCreateInitial.current,
          //     });
          //     await sleep(500);
          //     setStoredSignaturesInitialized(false);
          //   },
          // );

          // instance.addEventListener(
          //   "storedSignatures.delete",
          //   async (annotation: any) => {
          //     await deleteSignature({
          //       annotation,
          //       instance,
          //       PSPDFKit,
          //       userId: user?.id,
          //       isInitial: isCreateInitial.current,
          //     });
          //     await cleanChangedFields({
          //       instance,
          //       isInitial: isCreateInitial.current,
          //     });
          //     await sleep(500);
          //     setStoredSignaturesInitialized(false);
          //   },
          // );

          // instance.addEventListener(
          //   "annotations.create",
          //   async (createdAnnotations) => {
          //     const annotation = createdAnnotations.get(0);
          //     const toSaveSignature = sessionStorage.getItem("toSaveSignature");

          //     if (
          //       annotation &&
          //       annotation instanceof PSPDFKit.Annotations.InkAnnotation &&
          //       toSaveSignature !== "false"
          //     ) {
          //       saveInkAnnotationAtachment({
          //         annotation,
          //         instance,
          //         isInitial: isCreateInitial.current,
          //       });
          //     }
          //     if (
          //       annotation &&
          //       annotation instanceof PSPDFKit.Annotations.ImageAnnotation
          //     ) {
          //       await sleep(500);
          //     }
          //     if (isToDeleteSignature.current && annotation) {
          //       await instance.delete(annotation.id);
          //       isToDeleteSignature.current = false;
          //     }
          //     if (annotation?.customData?.savedSignatureId && instance) {
          //       const dataToSave = {
          //         id: annotation.customData.savedSignatureId as string,
          //         data: {
          //           isDefault: true,
          //         },
          //       };

          //       linkId
          //         ? await Signatures.putGuestSignature({
          //             docId: linkId,
          //             data: dataToSave,
          //           })
          //         : await Signatures.putSignature(dataToSave);

          //       await cleanAllSignatureFields({ instance, PSPDFKit });
          //       dispatch(setIsUpdated(true));
          //     }
          //     // if (annotation && toSaveSignature) {
          //     //   await saveSignatures({ annotation, instance, PSPDFKit });
          //     //   sessionStorage.removeItem("toSaveSignature");
          //     // }
          //   },
          // );

          const viewerContainer =
            instance.contentDocument.querySelector(".PSPDFKit-Scroll");

          instance.addEventListener(
            "viewState.currentPageIndex.change",
            (page: any) => {
              setOnPageIndex(page);
            },
          );

          instance.addEventListener("viewState.zoom.change", (zoom: number) => {
            setCurrentZoom(zoom);
          });

          instance.setViewState((viewState) => viewState.set("zoomStep", 1.5));

          instance.addEventListener("viewState.change", (viewState) => {
            sessionStorage.removeItem("toSaveSignature");
          });

          if (viewerContainer) {
            viewerContainer.addEventListener("scroll", () => {
              setCurrentScrollTop(viewerContainer.scrollTop);
              setCurrentScrollLeft(viewerContainer.scrollLeft);
            });
          }

          return () => PSPDFKit && PSPDFKit.unload(container);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    linkId,
    inboxDocId,
    ownerLinkId,
    sentDocId,
    docToSign?.file?.url,
    // activeRecipient,
    isCreateInitial,
    isBackup,
    // isSigningStarted,
    // annotationsChanged,
    // totalWidgets,
    // totalSigned,
    // storedSignaturesInitialized,
    // isToDeleteSignature,
    // storedSignatures,
    // storedAttachments,
    // isSignAllDisabled,
  ]);

  const mobileTop = docToSign?.status === "COMPLETED" ? "11rem" : "5.4rem";
  const top = isMobile ? mobileTop : "9.9rem";
  const heightWithFooter = `calc(100% - ${top} - ${
    isFooter || isMobile ? "8rem" : "0rem"
  })`;

  return (
    <>
      <RecipientSigningHeader
        requiredSigned={requiredSigned}
        optionalSigned={optionalSigned}
        totalOptionalWidgets={totalOptionalWidgets}
        totalRequiredWidgets={totalRequiredWidgets}
        isSigningStarted={isSigningStarted}
        onChangeSignature={handleChangeSignature}
        isOwner={isOwner}
        isBackup={isBackup}
      />
      <div
        onMouseEnter={() => {
          dispatch(setIsDetailedViewMenu(false));
        }}
        ref={containerRef}
        style={{
          width: "100%",
          height: heightWithFooter,
          zIndex: "100",
          position: "fixed",
          left: "0",
          top,
          backgroundColor: "white",
        }}
      />
      {!isMobile &&
        stateInstance &&
        isSigningStarted &&
        docToSign?.status === "NEED_TO_SIGN" && (
          <CounterInfo
            requiredSigned={requiredSigned}
            optionalSigned={optionalSigned}
            totalSigned={requiredSigned + optionalSigned}
            totalOptionalWidgets={totalOptionalWidgets}
            totalRequiredWidgets={totalRequiredWidgets}
            handleSignAll={handleSignAll}
            handleCleanAll={handleCleanAll}
            isSignAllDisabled={isSignAllDisabled}
          />
        )}
      {!isMobile &&
        docToSign?.status === "REJECTED" &&
        (inboxDocId || sentDocId) && <RejectionWidget />}
      {!isMobile &&
        (docToSign?.status === "NEED_TO_SIGN" ? isSigningStarted : true) && (
          <RecipientsWidget isGuest={!!(linkId || ownerLinkId)} />
        )}
      {!isMobile &&
        stateInstance &&
        docToSign?.status === "NEED_TO_SIGN" &&
        isSigningStarted && (
          <SignatureWidget
            onChangeSignature={handleChangeSignature}
            stateChanged={stateChanged}
          />
        )}
      {docToSign?.status === "NEED_TO_SIGN" && (
        <RecipientSigningFooter
          stateInstance={stateInstance}
          stateChanged={stateChanged}
          statePSPDFKit={statePSPDFKit}
          handleSignAll={handleSignAll}
          handleCleanAll={handleCleanAll}
          optionalSigned={optionalSigned}
          requiredSigned={requiredSigned}
          totalSigned={requiredSigned + optionalSigned}
          totalOptionalWidgets={totalOptionalWidgets}
          totalRequiredWidgets={totalRequiredWidgets}
          isSignAllDisabled={isSignAllDisabled}
          setIsFooter={setIsFooter}
          isFooter={isFooter}
          isSigningStarted={isSigningStarted}
          setIsSigningStarted={setIsSigningStarted}
          onDownloadFile={handleDownloadFile}
          onPageIndex={onPageIndex}
          currentZoom={currentZoom}
          currentScrollLeft={currentScrollLeft}
          currentScrollTop={currentScrollTop}
          isOwner={isOwner}
          isFieldsProcessing={isFieldsProcessing}
        />
      )}
    </>
  );
};
