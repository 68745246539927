import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, requestSigning } from "store";

import { Button, Icon } from "components/UI";

import styles from "./styles.module.scss";

type UpdateContactModalProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

export const UpdateContactModal: FC<UpdateContactModalProps> = ({
  onCancel,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "UpdateContact" });
  const { editedContact } = useAppSelector(requestSigning);
  const {
    newFirstName,
    newLastName,
    oldFirstName,
    oldLastName,
    newEmail,
    oldEmail,
  } = editedContact || {};

  const oldName = `${oldFirstName} ${oldLastName}` || "";
  const newName = `${newFirstName} ${newLastName}` || "";

  return (
    <div className={styles.UpdateContactModal}>
      <div className={styles.modalHeader}>
        <Icon name="close" className={styles.close} action={onCancel} />
        <h2 className={styles.title}>{t("title")}</h2>
        <div className={styles.infoWrap}>
          {newName !== oldName && (
            <p>
              {t("infoName", {
                oldName,
                newName,
              })}
            </p>
          )}
          {newEmail !== oldEmail && (
            <p>
              {t("infoEmail", {
                oldEmail,
                newEmail,
              })}
            </p>
          )}
        </div>
      </div>

      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={t("confirm")}
          onClick={onSubmit}
          className={styles.confirmButton}
        />
        <Button variant="secondary" title={t("cancel")} onClick={onCancel} />
      </div>
    </div>
  );
};
