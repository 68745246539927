import { FC, useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

import { useAppDispatch, useAppSelector, signings } from "store";
import {
  setSelectedInboxItems,
  setInboxIsLoading,
  setIsInboxItemsMoved,
  setIsDeleteDocUpdated,
} from "store/signings";
import { getInboxDocs } from "store/signings/thunks";
import { setItemsToMove } from "store/folders";

import { NoTableData } from "components";
import { Checkbox, Icon, Spinner } from "components/UI";
import { cs, getMenuTitle, getSigningsItems } from "utils";
import { TSharedDocumentStatus, IInboxDoc } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { SigningsInboxTableModals } from "./Modals";
import { SigningsInboxTableRow } from "./Row";
import styles from "./styles.module.scss";

type FieldSorted = "name" | "receivedAt";
type OrderSorted = "DESC" | "ASC";

type SortingType = {
  field: FieldSorted;
  order: OrderSorted;
};

type SigningsInboxTableProps = {
  status?: TSharedDocumentStatus;
};

export const SigningsInboxTable: FC<SigningsInboxTableProps> = ({ status }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsInbox",
  });
  const { t: tG } = useTranslation("General");
  const {
    inboxMeta,
    search,
    inboxDocs,
    selectedInboxItems,
    isInboxLoading,
    isInboxItemsMoved,
    isDeleteDocUpdated,
  } = useAppSelector(signings);
  const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const [tableData, setTableData] = useState<IInboxDoc[]>(inboxDocs);
  const storageSorting = localStorage.getItem("sortingSigningsInbox");
  const parsedSorting = storageSorting && JSON.parse(storageSorting);
  const [sorting, setSorting] = useState<SortingType>({
    field: parsedSorting?.field || "receivedAt",
    order: parsedSorting?.order || "DESC",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItem, setCurrentItem] = useState<IInboxDoc | null>(null);

  const handleSort = useCallback(
    (field: FieldSorted) => {
      const newSortData = {
        field,
        order: (sorting.order === "ASC" ? "DESC" : "ASC") as OrderSorted,
      };
      localStorage.setItem("sortingSigningsInbox", JSON.stringify(newSortData));
      setSorting(newSortData);
    },
    [sorting.order],
  );

  const convertStatus = useCallback(() => {
    if (status === "NEED_TO_SIGN") {
      return "need_to_sign";
    }
    if (status === "WAITING_FOR_OTHERS") {
      return "waiting_for_others";
    }
    if (status === "COMPLETED") {
      return "completed";
    }
    if (status === "CANCELED") {
      return "voided";
    }
    return "all";
  }, [status]);

  const getStateDocs = useCallback(async () => {
    const convertedSatus = convertStatus();

    dispatch(setInboxIsLoading(true));
    await dispatch(
      getInboxDocs({
        filter: convertedSatus,
        page: 1,
        limit: currentPage * 15,
        orderBy: sorting.field === "receivedAt" ? "received_at" : sorting.field,
        order: sorting.order,
      }),
    );
    dispatch(setIsDeleteDocUpdated(false));
    dispatch(setInboxIsLoading(false));
    if (isInboxItemsMoved) {
      dispatch(setIsInboxItemsMoved(false));
    }
  }, [
    dispatch,
    sorting.field,
    sorting.order,
    convertStatus,
    currentPage,
    isInboxItemsMoved,
  ]);

  useEffect(() => {
    getStateDocs();
  }, [getStateDocs, isDeleteDocUpdated]);

  useEffect(() => {
    setTableData(inboxDocs);
  }, [inboxDocs]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedInboxItems([]));
    setTableData([]);
    setCurrentPage(1);
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleSelectAll = () => {
    dispatch(
      setSelectedInboxItems(
        selectedInboxItems.length < tableData?.length ? [...tableData] : [], // .filter((el) => el.status !== "IN_PROGRESS" || status)
      ),
    );
  };

  const handleSelectItem = (item: IInboxDoc) => {
    dispatch(
      setSelectedInboxItems(
        selectedInboxItems.some((el) => el.id === item.id)
          ? [...selectedInboxItems.filter((el) => el.id !== item.id)]
          : [...selectedInboxItems, item],
      ),
    );
    setCurrentItem(item);
  };

  const handleLoadingMore = () => {
    if (!isInboxLoading) {
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  const refreshState = () => {
    dispatch(setItemsToMove([]));
    handleClearSelected();
    getStateDocs();
  };

  return (
    <div className={styles.SigningsInboxTable}>
      <SigningsInboxTableModals
        refreshState={refreshState}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
      <header
        className={cs([
          styles.tr,
          selectedInboxItems.length > 0 && styles.active,
        ])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedInboxItems.length === tableData.length &&
            selectedInboxItems.length > 0
          }
        />
        {selectedInboxItems.length > 0 ? (
          <ul className={styles.actions}>
            {getSigningsItems({
              selectedItemsCount: selectedInboxItems.length,
              signingsType: "inbox",
              isAllShowed: true,
              isAllTab: true,
              signingsStatus: status,
              isSelectedItemsNeedToSign: selectedInboxItems.every(
                (el) => el.status === "NEED_TO_SIGN",
              ),
              isSelectedItemsWaiting: selectedInboxItems.every(
                (el) => el.status === "WAITING_FOR_OTHERS",
              ),
              isSelectedItemsCompleted: selectedInboxItems.every(
                (el) => el.status === "COMPLETED",
              ),
              isSelectedItemsVoided: selectedInboxItems.every((el) =>
                ["CANCELED", "REJECTED", "EXPIRED"].includes(el.status),
              ),
            })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({
                  name: el,
                  signingsType: "inbox",
                  signingsStatus: status,
                })}
              >
                <Icon name={el} size={18} />{" "}
                {getMenuTitle({
                  name: el,
                  signingsType: "inbox",
                  signingsStatus: status,
                })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell
              name={tG("name")}
              onSort={() => handleSort("name")}
              isSorted={sorting.field === "name" && sorting.order === "ASC"}
            />
            <HeaderCell name={tG("status")} />
            <HeaderCell name={tG("fileID")} />
            <HeaderCell
              name={t("receivedAt")}
              onSort={() => handleSort("receivedAt")}
              isSorted={
                sorting.field === "receivedAt" && sorting.order === "ASC"
              }
            />
          </>
        )}
      </header>
      <div className={styles.list}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          useWindow={false}
          loadMore={handleLoadingMore}
          hasMore={Number(inboxMeta?.totalItems) > tableData.length}
          loader={
            <div key={0} className={styles.loader}>
              <Spinner />
            </div>
          }
          threshold={20}
        >
          {tableData.map((item) => (
            <div key={item.id}>
              <SigningsInboxTableRow
                onChange={() => handleSelectItem(item)}
                item={item}
                signingsType="inbox"
                signingsStatus={status}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <NoTableData
        isSearch={!!searchValue}
        isNoFilteredData={tableData?.length === 0}
        isNoData={tableData?.length === 0}
        isSigningsInbox
        signingsStatus={status}
      />
    </div>
  );
};
