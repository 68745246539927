import { FC, useState, useCallback, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import axios from "axios";
import { useLocation } from "react-router-dom";

import {
  useAppSelector,
  useAppDispatch,
  settings,
  dashboard,
  requestSigning,
} from "store";
import { setFilesToSave, setFilesLimit } from "store/requestSigning";
import { PDF, SharedDocuments } from "api";

import { ContactSelector, DocImage, SelectFromMyDocsModal } from "components";
import { Input, Button, Modal } from "components/UI";

import { useUploadRequest, useEffectOnce } from "hooks";
import { toBase64, toastError, toastSuccess } from "utils";
import { IContact, DataType } from "types";
import { MAX_EMAIL_FILE_SIZE, MAX_FILE_SIZE } from "constants/index";

import removeIcon from "assets/img/icons/remove-button.svg";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type SendEmailModalProps = {
  onClose: () => void;
};

type TFileUrl = { title: string; url: string };

export const SendEmailModal: FC<SendEmailModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "SendEmail" });
  const { openedDocument } = useAppSelector(dashboard);
  const { uploadFiles } = useUploadRequest();
  const { filesToSave, filesLimit } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  const { pathname } = useLocation();
  const isDocCertified = pathname.includes("certified");
  const defaultDocName = `${user?.firstName} ${user?.lastName} via ApostiSign shared ${openedDocument?.name}`;
  const defaultMessage = `Hello,
  
The document is attached for your review.

Best Regards,

${user?.firstName} ${user?.lastName}`;
  const [subject, setSubject] = useState<string>(defaultDocName);
  const [message, setMessage] = useState<string>(defaultMessage);
  const [isAddFileModal, setIsAddFileModal] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stateFileUrl, setStateFileUrl] = useState<TFileUrl[]>([]);
  const [recipientsTo, setRecipientsTo] = useState<IContact[]>([]);
  const [recipientsCC, setRecipientsCC] = useState<IContact[]>([]);
  const [allFiles, setAllFiles] = useState<DataType[]>([]);

  const handleClose = useCallback(() => {
    dispatch(setFilesToSave([]));
    dispatch(setFilesLimit(MAX_FILE_SIZE));
    setRecipientsTo([]);
    setRecipientsCC([]);
    setMessage("");
    setSubject("");
    onClose();
  }, [dispatch, onClose]);

  const handleInitFile = async () => {
    if (!openedDocument) return;

    const res = isDocCertified
      ? await PDF.getSignedPdfDocument(openedDocument.id)
      : await PDF.getPdfDocument(openedDocument.id);

    const { url = "", name = "", fileSize = 0 } = res?.document || {};
    if (fileSize > MAX_EMAIL_FILE_SIZE) {
      handleClose();
      toastError(t("toastSizeError", { fileSizeLimit: 20 }));
    }
    const s3Res = await axios.get(url, { responseType: "blob" });
    const uploadedFile = await s3Res.data;
    const selectedFilesToUpload = new File([uploadedFile], name, {
      type: "application/pdf",
    });

    selectedFilesToUpload &&
      uploadFiles([selectedFilesToUpload], [openedDocument.id]);
  };

  useEffectOnce(() => {
    dispatch(setFilesLimit(MAX_EMAIL_FILE_SIZE));
    if (filesToSave.length === 0) {
      handleInitFile();
    }
  });

  const handleClearFile = useCallback(
    (index: number) => {
      const newArr = filesToSave.slice();
      const removedFile = newArr.splice(index, 1);
      dispatch(setFilesToSave(newArr));
      dispatch(setFilesLimit(filesLimit + removedFile[0].size));
    },
    [dispatch, filesLimit, filesToSave],
  );

  const getStateFileImage = useCallback(async () => {
    if (filesToSave.length > 0) {
      filesToSave
        .filter((el) => el.size > 0)
        .forEach(async (el) => {
          const url = await toBase64(el.data);
          if (typeof url === "string") {
            setStateFileUrl((prevState) => [
              ...prevState.filter((item) => item.title !== el.title),
              { title: el.title, url },
            ]);
          }
        });
    }
  }, [filesToSave]);

  useEffect(() => {
    getStateFileImage();
  }, [getStateFileImage]);

  useEffect(() => {
    if (filesToSave.length > 1) {
      const totalFilesSize = filesToSave.reduce(
        (acc, curVa) => acc + curVa.size,
        0,
      );
      if (totalFilesSize > MAX_EMAIL_FILE_SIZE) {
        handleClearFile(filesToSave.length - 1);
        toastError(t("toastSizeError", { fileSizeLimit: 20 }));
      }
    }
  }, [filesToSave, handleClose, t, handleClearFile]);

  const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setSubject(e.target.value);
  };

  const handleChangeMeassage = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(value);
  };

  const handleSubmit = async () => {
    if (filesToSave.length === 0) return;
    // setIsLoading(true);
    const filesIds = filesToSave.map((el) => el.id || "");
    const recipientsToEmails = recipientsTo.map((el) => el.email);
    const recipientsCCEmails = recipientsCC.map((el) => el.email);
    const res = await SharedDocuments.shareDocuments({
      documentIds: filesIds,
      to: recipientsToEmails,
      cc: recipientsCCEmails,
      body: message.replace(/\n/g, "<br />"),
      subject,
    });
    if (res?.requestId) {
      handleClose();
    }
  };

  const handleOpenSelectModal = () => {
    setIsAddFileModal(true);
  };

  const handleCloseSelectModal = () => {
    setIsAddFileModal(false);
  };

  const handleCancel = () => {
    handleClose();
    toastSuccess(t("toastCancel"));
  };

  const handleUpdateAllFiles = (selectedFiles: DataType[]) => {
    setAllFiles((prevState) => [...prevState, ...selectedFiles]);
  };

  return (
    <div className={styles.SendEmailModal}>
      <Portal>
        <Modal isShowed={isAddFileModal} styleWrap={{ zIndex: 200 }} noDarkness>
          <SelectFromMyDocsModal
            onClose={handleCloseSelectModal}
            onUpload={uploadFiles}
            onUpdateAllFiles={handleUpdateAllFiles}
          />
        </Modal>
      </Portal>
      <ModalHeader onClose={handleCancel} title={t("title")} />
      <ContactSelector
        label={t("toLabel")}
        recipients={recipientsTo}
        allRecipients={[...recipientsTo, ...recipientsCC]}
        setRecipients={setRecipientsTo}
        isRequired
      />
      <ContactSelector
        label={t("ccLabel")}
        recipients={recipientsCC}
        allRecipients={[...recipientsTo, ...recipientsCC]}
        setRecipients={setRecipientsCC}
      />
      <Input
        onChange={handleChangeSubject}
        value={subject}
        name="subject"
        isClearButton
        onClear={() => setSubject("")}
        placeholder={t("subjectPlaceholder")}
        label={t("subjectLabel")}
        className={styles.inputWrapper}
        isRequired
      />
      <div className={styles.message}>
        <div className={styles.label}>
          {t("messageLabel")}
          <span className={styles.labelError}> *</span>
        </div>
        <textarea
          name="message"
          cols={30}
          rows={10}
          onChange={handleChangeMeassage}
          value={message}
          maxLength={500}
          placeholder={t("messagePlaceholder")}
        />
      </div>
      <div className={styles.files}>
        <div className={styles.filesHeader}>
          <h3 className={styles.filesTitle}>{t("attachedTitle")}</h3>
          <Button
            variant="textBlack"
            size="sm"
            title={t("attachButtonTitle")}
            onClick={handleOpenSelectModal}
            iconStart="add-in-circle"
            iconStartType="stroke"
            className={styles.addFileButton}
          />
        </div>
        <div className={styles.uploadedFiles}>
          {filesToSave.map((el, index) => (
            <div className={styles.uploadedFile} key={`${el.title}${index}`}>
              <div className={styles.fileImg}>
                <DocImage
                  type={
                    (openedDocument?.id === el.id
                      ? openedDocument?.type
                      : allFiles.find((file) => file.id === el.id)?.type) ??
                    "PDF"
                  }
                  previewUrl={
                    stateFileUrl.find((item) => el.title === item.title)?.url
                  }
                  isPdf
                />
                <img
                  onClick={() => handleClearFile(index)}
                  src={removeIcon}
                  alt="remove"
                  className={styles.clearIcon}
                />
              </div>
              {filesToSave.length > 0 && (
                <>
                  <div className={styles.fileTitle} title={el.title}>
                    {el.title}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={handleCancel}
        />

        <Button
          title={
            // isLoading ? (
            //   <Spinner color={palette.white} />
            // ) : (
            t("submitButtonTitle")
            // )
          }
          variant="primary"
          onClick={handleSubmit}
          isDisabled={
            filesToSave.length === 0 ||
            recipientsTo.length === 0 ||
            !subject ||
            !message
          }
        />
      </div>
    </div>
  );
};
