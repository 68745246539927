import { AxiosError, isAxiosError } from "axios";
import queryString from "query-string";

import { toastError, authErrors } from "utils";
import { IHttpRejection, IFolder, IParent, ISearchFolder } from "types";

import { $http } from "./instance";

interface IGetTrashFolder {
  id: string;
}

interface IGetFolderResponse {
  items: IParent[];
  folder: IFolder;
  parentFolder: IFolder;
}

// interface IGetDocumentResponse {
//   items: IFolderGeneral[];
// }

interface ISearchFolderResponse {
  items: ISearchFolder[];
}

interface IDeleteFolder {
  folderIds: string[];
}

interface ISearchFolderRequset {
  query: string;
  isFoldersOnly?: string;
}

export interface IRestoreFolder {
  folderIds: string[];
}
export interface IUndoDocument {
  folderIds: string[];
}
interface IUndoDocumentResponse {
  message: string;
}

export const TrashBin = {
  getDocuments: async (): Promise<any> => {
    try {
      const res = await $http.get(`/clients/deleted-documents`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      }
    }
  },
  getFolder: async ({
    id,
  }: IGetTrashFolder): Promise<IGetFolderResponse | undefined> => {
    try {
      const res = await $http.get(
        `/clients/trash/folders/${id}?timeZone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getFolderParents: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/trash/folders/${id}/parents`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getFolderFiles: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/trash/folders/${id}/files`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getPDFDocument: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(
        `/clients/trash/documents/${id}?timeZone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getSignedPDFDocument: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(
        `/clients/trash/documents/signed/${id}?timeZone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteFolder: async (data: IDeleteFolder): Promise<any> => {
    try {
      const res = await $http.delete(`/clients/trash/folders`, { data });
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  searchFolder: async ({
    query,
    isFoldersOnly = "false",
  }: ISearchFolderRequset): Promise<ISearchFolderResponse | undefined> => {
    const queries = queryString.stringify(
      { query, isFoldersOnly },
      { skipEmptyString: true },
    );
    try {
      const res = await $http.get(`/clients/trash/folders/search?${queries}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  restoreFolder: async (data: IRestoreFolder): Promise<any> => {
    try {
      const res = await $http.post(`/clients/trash/folders/restore`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  undoDocument: async (
    data: IUndoDocument,
  ): Promise<IUndoDocumentResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/trash/folders/undo`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
};
