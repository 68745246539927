import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { Settings } from "api";
import { setUser } from "store/settings";

import { Button, Input, Spinner } from "components/UI";

import { toastSuccess, palette } from "utils";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type EditCompanyNameModalProps = {
  onClose: () => void;
};

export const EditCompanyNameModal: FC<EditCompanyNameModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "EditCompanyName" });
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(settings);
  const [companyName, setCompanyName] = useState<string>(
    user?.companyName || "",
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (isLoading) return;
    // if (companyName) {
    setIsLoading(true);
    const res = await Settings.changeName({
      // firstName: user?.firstName,
      // lastName: user?.lastName,
      companyName: companyName || null,
    });

    if (res?.id) {
      dispatch(setUser(res));
      toastSuccess(t("toastSuccess"));
    }
    setIsLoading(false);
    onClose();
    // }
  };

  const handleChangeName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 100) return;
    setCompanyName(target.value);
  };

  return (
    <div className={styles.EditCompanyNameModal}>
      <ModalHeader onClose={handleClose} title={t("title")} />
      <Input
        className={styles.nameInput}
        onChange={handleChangeName}
        value={companyName}
        name="companyName"
        placeholder={t("companyNamePlaceholder")}
        label={t("companyNameLabel")}
        autoComplete="off"
      />

      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={
            isLoading ? (
              <Spinner color={palette.white} />
            ) : (
              t("submitButtonTitle")
            )
          }
          onClick={handleSubmit}
          // isDisabled={!companyName}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};
