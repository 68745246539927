import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, dashboard } from "store";
import { setLanguage } from "store/dashboard";

import { Button, RadioButton } from "components/UI";

import { toastSuccess } from "utils";
// import i18n from "i18n";

import i18n, { initI18n } from "i18n";
import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type ChangeLanguageModalProps = {
  onClose: () => void;
};

export const ChangeLanguageModal: FC<ChangeLanguageModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "ChangeLanguage" });
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(dashboard);
  const [lang, setLang] = useState<string>(language);
  const languages = [
    { title: t("english"), value: "en" },
    { title: t("french"), value: "fr" },
    { title: t("german"), value: "de" },
    { title: t("italian"), value: "it" },
    { title: t("spanish"), value: "es" },
    { title: t("portuguese"), value: "pt" },
    { title: t("polish"), value: "pl" },
    { title: t("ukrainian"), value: "uk" },
    { title: t("hebrew"), value: "he" },
  ];

  const handleClose = () => {
    i18n.changeLanguage(language);
    onClose();
  };

  const handleSubmit = () => {
    dispatch(setLanguage(lang));
    localStorage.setItem("lng", lang);
    initI18n(() => {});
    onClose();
    toastSuccess(t("toastSuccess"));
  };

  const handleChange = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setLang(name);
    i18n.changeLanguage(name);
  };

  return (
    <div className={styles.ChangeLanguageModal}>
      <ModalHeader onClose={handleClose} title={t("title")} info={t("info")} />
      <ul className={styles.checkList}>
        {languages.map((el, index) => (
          <li key={index}>
            <RadioButton
              onChange={handleChange}
              title={el.title}
              name={el.value}
              isChecked={lang === el.value}
            />
          </li>
        ))}
      </ul>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={t("submitButtonTitle")}
          onClick={handleSubmit}
          isDisabled={lang === language}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};
