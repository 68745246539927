import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IRecipient,
  IFolderParents,
  IRequestFile,
  DataType,
  IDetailedDraft,
  TEditedContact,
} from "types";
import { MAX_FILE_SIZE } from "constants/index";

import { getFolderParents } from "./thunks";
import { getRootFolder } from "../folders/thunks";
import { getDraft } from "../signings/thunks";

type RecipientWithColor = {
  recipient: string;
  color: string;
};

export type RequestSigningState = {
  search: string;
  recipients: IRecipient[];
  activeRecipient: IRecipient | null;
  currentFolder: string;
  currentFolderParents: IFolderParents | null;
  filesToSave: IRequestFile[];
  fileName: string;
  isAddFileModal: boolean;
  filesLimit: number;
  selectedItems: DataType[];
  isSearch: boolean;
  isUploadInProgress: boolean;
  editedDraft: IDetailedDraft | null;
  isDeleteDraftModal: boolean;
  isRequestReady: boolean;
  usedColors: RecipientWithColor[];
  editedContact: TEditedContact | null;
};

const initialState: RequestSigningState = {
  search: "",
  recipients: [{ id: "", firstName: "", lastName: "", email: "", error: "" }],
  activeRecipient: null,
  currentFolder: "",
  currentFolderParents: null,
  filesToSave: [],
  fileName: "",
  isAddFileModal: false,
  filesLimit: MAX_FILE_SIZE,
  selectedItems: [],
  isSearch: false,
  isUploadInProgress: false,
  editedDraft: null,
  isDeleteDraftModal: false,
  isRequestReady: false,
  usedColors: [],
  editedContact: null,
};

const requestSigningSlice = createSlice({
  name: "requestSigning",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setIsSearch(state, { payload }: PayloadAction<boolean>) {
      state.isSearch = payload;
    },

    setRecipients(state, { payload }: PayloadAction<IRecipient[]>) {
      state.recipients = payload;
    },
    setActiveRecipient(state, { payload }: PayloadAction<IRecipient | null>) {
      state.activeRecipient = payload;
    },
    setCurrentFolder(state, { payload }: PayloadAction<string>) {
      state.currentFolder = payload;
    },
    setFilesToSave(state, { payload }: PayloadAction<IRequestFile[]>) {
      state.filesToSave = payload;
    },
    setFileName(state, { payload }: PayloadAction<string>) {
      state.fileName = payload;
    },
    setIsAddFileModal(state, { payload }: PayloadAction<boolean>) {
      state.isAddFileModal = payload;
    },
    setFilesLimit(state, { payload }: PayloadAction<number>) {
      state.filesLimit = payload;
    },
    setSelectedItems(state, { payload }: PayloadAction<DataType[]>) {
      state.selectedItems = payload;
    },
    setIsUploadInProgress(state, { payload }: PayloadAction<boolean>) {
      state.isUploadInProgress = payload;
    },
    setEditedDraft(state, { payload }: PayloadAction<IDetailedDraft | null>) {
      state.editedDraft = null;
    },
    setIsDeleteDraftModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteDraftModal = payload;
    },
    setIsRequestReady(state, { payload }: PayloadAction<boolean>) {
      state.isRequestReady = payload;
    },
    setUsedColors(state, { payload }: PayloadAction<RecipientWithColor[]>) {
      state.usedColors = payload;
    },
    setEditedContact(state, { payload }: PayloadAction<TEditedContact | null>) {
      state.editedContact = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolderParents.fulfilled, (state, action) => {
      state.currentFolderParents = action.payload;
    });
    builder.addCase(getRootFolder.fulfilled, (state, action) => {
      state.currentFolder = action.payload?.id;
    });
    builder.addCase(getDraft.fulfilled, (state, action) => {
      state.editedDraft = action.payload || null;
    });
  },
});

export const {
  setSearch,
  setRecipients,
  setCurrentFolder,
  setFilesToSave,
  setFileName,
  setIsAddFileModal,
  setFilesLimit,
  setSelectedItems,
  setIsSearch,
  setIsUploadInProgress,
  setEditedDraft,
  setActiveRecipient,
  setIsDeleteDraftModal,
  setIsRequestReady,
  setUsedColors,
  setEditedContact,
} = requestSigningSlice.actions;

export default requestSigningSlice.reducer;
