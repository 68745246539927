import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import en from "./assets/locales/en.json";
// import fr from "./assets/locales/fr.json";
// import de from "./assets/locales/de.json";
// import es from "./assets/locales/es.json";
// import pl from "./assets/locales/pl.json";
// import pt from "./assets/locales/pt.json";
// import uk from "./assets/locales/uk.json";
// import he from "./assets/locales/he.json";
// import it from "./assets/locales/it.json";

// const resources = {
//   en,
//   fr,
//   de,
//   es,
//   pl,
//   pt,
//   uk,
//   he,
//   it,
// };

export const fetchLanguage = async (code: string) => {
  try {
    const response = await fetch(
      `https://delivery.localazy.com/_a735592758085355276949bb20a9/_e0/35ab5a9c069cbb0dc944b27591bb95336fb8db8c/${code}/file.json`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch translations for ${code}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error loading translations:", error);
    return {};
  }
};

export const supportedLanguages = [
  "en",
  "fr",
  "de",
  "es",
  "pl",
  "pt",
  "uk",
  "he",
  "it",
];

export const browserLng = navigator.language?.split("-")[0];
export const defaultLanguage = supportedLanguages.includes(browserLng)
  ? browserLng
  : "en";

export const initI18n = async (callback: () => void) => {
  const defaultLang = localStorage.getItem("lng") || defaultLanguage;
  const translations = await fetchLanguage(defaultLang);

  i18n.use(initReactI18next).init(
    {
      lng: defaultLang,
      fallbackLng: defaultLang,
      debug: true,
      resources: {
        [defaultLang]: translations,
      },
      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      if (err) console.error("i18n init error:", err);
      callback();
    },
  );

  i18n.addResourceBundle(defaultLang, defaultLang, translations);
};

// i18n.use(initReactI18next).init({
//   resources,
//   // lng: "en",
//   // fallbackLng: "en",
//   // defaultNS: "en",
//   fallbackNS: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// i18n.addResourceBundle("en", "en", en);
// i18n.addResourceBundle("fr", "fr", fr);
// i18n.addResourceBundle("de", "de", de);
// i18n.addResourceBundle("es", "es", es);
// i18n.addResourceBundle("pl", "pl", pl);
// i18n.addResourceBundle("pt", "pt", pt);
// i18n.addResourceBundle("uk", "uk", uk);
// i18n.addResourceBundle("he", "he", he);
// i18n.addResourceBundle("it", "it", it);

export default i18n;

export const ns = ["en", "fr", "de", "es", "pl", "pt", "uk", "he", "it"];
