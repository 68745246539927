import { FC, ChangeEvent, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, requestSigning, folders } from "store";
import { setSearch, setIsSearch, setSelectedItems } from "store/requestSigning";
import { PDF } from "api";
import { DataType } from "types";

import { SelectFromMyDocsTable } from "components";
import { Search, Button, Spinner } from "components/UI";
import { palette, cs, toastError } from "utils";
import { MAX_EMAIL_FILE_SIZE } from "constants/index";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type SelectFromMyDocsModalProps = {
  onClose: () => void;
  onUpdateAllFiles?: (files: DataType[]) => void;
  onUpload: (acceptedFiles: File[], id?: string[]) => void;
};

export const SelectFromMyDocsModal: FC<SelectFromMyDocsModalProps> = ({
  onClose,
  onUpload,
  onUpdateAllFiles,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "SelectFromMyDocs" });
  const { t: tS } = useTranslation("Modals", { keyPrefix: "SendEmail" });
  const { search, selectedItems } = useAppSelector(requestSigning);
  const { isSendEmailModal } = useAppSelector(folders);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const handleClose = () => {
    onClose();
    dispatch(setSelectedItems([]));
    dispatch(setSearch(""));
  };

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const selectedFilesToUpload = await Promise.all(
        selectedItems.map(async (item) => {
          const res =
            item.type === "PDF_CERTIFIED"
              ? await PDF.getSignedPdfDocument(item.id)
              : await PDF.getPdfDocument(item.id);
          const { url = "", name = "", fileSize = 0 } = res?.document || {};
          if (isSendEmailModal && fileSize > MAX_EMAIL_FILE_SIZE) {
            toastError(tS("toastSizeError", { fileSizeLimit: 20 }));
            handleClose();
          }
          const s3Res = await axios.get(url, { responseType: "blob" });
          const uploadedFile = await s3Res.data;
          return new File([uploadedFile], name, {
            type: "application/pdf",
          });
        }),
      );

      selectedFilesToUpload &&
        onUpload(
          selectedFilesToUpload,
          selectedItems.map((el) => el.id),
        );
      isSendEmailModal && onUpdateAllFiles && onUpdateAllFiles(selectedItems);
      handleClose();
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSearch = () => {
    dispatch(setSearch(""));
    dispatch(setIsSearch(false));
  };

  const submitButtonText =
    selectedItems.length > 1
      ? t("submitButtonTitlePlural", {
          itemsCount: `${selectedItems.length}`,
        })
      : t("submitButtonTitle", {
          itemsCount: `${selectedItems.length || ""}`,
        });

  return (
    <div className={styles.SelectFromMyDocsModal}>
      <ModalHeader onClose={handleClose} title={t("title")} />
      <div className={cs([styles.searchField, search && styles.withButton])}>
        <Search
          className={styles.search}
          onChange={handleSearch}
          value={search}
          onClear={() => dispatch(setSearch(""))}
        />
        {search && (
          <Button
            variant="textBlack"
            size="sm"
            title={t("cancelButtonTitle")}
            onClick={handleCancelSearch}
            className={styles.cancelButton}
          />
        )}
      </div>
      <SelectFromMyDocsTable />
      <Button
        variant="primary"
        size="lg"
        title={isLoading ? <Spinner color={palette.white} /> : submitButtonText}
        isDisabled={selectedItems.length === 0}
        onClick={handleSubmit}
        className={styles.submitButton}
      />
    </div>
  );
};
