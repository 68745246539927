import { FC, useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { isAxiosError } from "axios";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, folders, requestSigning } from "store";
import { getFolder } from "store/folders/thunks";
import { setIsSearch } from "store/requestSigning";

import { NoTableData } from "components";
import { toastError, transformData, cs } from "utils";
import { DataType, IParent, FolderNode, FolderItem } from "types";
import { Folders } from "api";

import { HeaderCell } from "../HeaderCell";
import { SelectFromMyDocsTableRow } from "./Row";

import styles from "./styles.module.scss";

type FieldSorted = "name" | "createdAt";

export const SelectFromMyDocsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SelectFromMyDocs",
  });
  const { t: tG } = useTranslation("General");
  const { allGeneralItems, isSendEmailModal } = useAppSelector(folders);
  const { currentFolder, search, isSearch, filesToSave } =
    useAppSelector(requestSigning);
  const [searchValue] = useDebounce(search, 1000);
  const [tableData, setTableData] = useState<IParent[]>([]);
  const [sorting, setSorting] = useState({
    name: false,
    createdAt: false,
  });
  const [openedFolders, setOpenedFolders] = useState<string[]>([]);

  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)?.items,
    [allGeneralItems, currentFolder],
  );

  const newData = useMemo(
    () => transformData(allGeneralItems),
    [allGeneralItems],
  );

  const initialData = useMemo(() => openedFolderData || [], [openedFolderData]);

  const sortTable = useCallback(
    (field: FieldSorted) => {
      if (tableData.length > 0) {
        setTableData((prevState) =>
          prevState.slice().sort((a: DataType, b: DataType) => {
            let asc = sorting[field] ? 1 : -1;
            let desc = sorting[field] ? -1 : 1;

            if (field === "createdAt") {
              asc = sorting[field] ? -1 : 1;
              desc = sorting[field] ? 1 : -1;
            }

            return a[field]?.toLocaleLowerCase() < b[field]?.toLocaleLowerCase()
              ? asc
              : desc;
          }),
        );
      }
    },
    [sorting, tableData.length],
  );

  const handleSort = useCallback(
    (field: FieldSorted) => {
      setSorting((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
      }));
      sortTable(field);
    },
    [sortTable],
  );

  const handleDeepSort = useCallback((field: FieldSorted) => {
    field === "name"
      ? setSorting((prevState) => ({
          createdAt: false,
          [field]: !prevState[field],
        }))
      : setSorting((prevState) => ({
          name: false,
          [field]: !prevState[field],
        }));
  }, []);

  useEffect(() => {
    if (currentFolder) {
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder]);

  useEffect(() => {
    if (searchValue && !isSearch) {
      dispatch(setIsSearch(true));
    }
  }, [searchValue, dispatch, isSearch]);

  const searchItems = useCallback(async () => {
    if (searchValue) {
      try {
        const res = await Folders.searchFolder({
          query: searchValue,
          filter: "originals_with_folders",
        });
        if (res?.items) {
          setTableData(res.items.filter((el) => el.type !== "FOLDER"));
        }
      } catch (error) {
        console.log("error:", error);
        if (isAxiosError(error)) {
          error?.message &&
            toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        }
      }
    } else {
      setTableData(openedFolderData || []);
    }
  }, [searchValue, openedFolderData]);

  useEffect(() => {
    searchItems();
  }, [searchItems]);

  const handleOpenFolder = async (folder: DataType) => {
    dispatch(getFolder({ id: folder.id }));
    const childFolders =
      allGeneralItems.find((item) => item.folder?.id === folder.id)?.items ||
      [];

    if (!openedFolders.includes(folder.id)) {
      await Promise.all(
        childFolders
          ?.filter((item) => item.type === "FOLDER")
          ?.map(async (item) => {
            // eslint-disable-next-line no-return-await
            return dispatch(getFolder({ id: item.id }));
          }),
      );
    }

    setOpenedFolders((prevState) =>
      prevState.includes(folder.id)
        ? [...prevState.filter((el) => el !== folder.id)]
        : [...prevState, folder.id],
    );
  };

  const sortFolderItems = (a: FolderItem, b: FolderItem) => {
    if (sorting.name) {
      return a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase() ? 1 : -1;
    }
    if (sorting.createdAt) {
      return a.modifiedAt?.toLocaleLowerCase() <
        b.modifiedAt?.toLocaleLowerCase()
        ? -1
        : 1;
    }
    return 1;
  };

  const filteredData = useMemo(
    () =>
      tableData
        ?.filter(
          (el) => isSendEmailModal || el.type === "FOLDER" || el.type === "PDF",
        )
        .filter((folderItem) => {
          return isSendEmailModal
            ? filesToSave.every((savedFile) => folderItem.id !== savedFile.id)
            : true;
        })
        .sort((a: DataType, b: DataType) => {
          return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
        }),
    [tableData, isSendEmailModal, filesToSave],
  );

  const renderSubFolders = (elements?: FolderNode[]) => {
    if (!elements) {
      return;
    }

    return elements?.map((el, index) => {
      const folderItems = el.items.filter(
        (child) => child.parentFolderId === el.folder.id,
      );
      if (el.items.length === 0) {
        return (
          <div key={`${el.folder.id}${index}`} className={styles.folder}>
            {folderItems
              .sort(sortFolderItems)
              .sort((a: FolderItem, b: FolderItem) => {
                return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
              })
              .map((folderItem) => (
                <SelectFromMyDocsTableRow
                  item={{
                    ...folderItem,
                    deletedAt: "",
                    parentFolderId: folderItem.parentFolderId || "",
                    childrenCount: folderItems.length - 1,
                  }}
                  isOpened={openedFolders.includes(el.folder.id)}
                  onOpenFolder={handleOpenFolder}
                  indent={(el.folder.parentsCount || 1) * 2}
                />
              ))}
          </div>
        );
      }
      if (el.items.length > 0) {
        return [
          <Fragment key={`${el.folder.name}${index}`}>
            <div className={styles.folder}>
              {folderItems
                .filter(
                  (folderItem) =>
                    isSendEmailModal ||
                    folderItem.type === "FOLDER" ||
                    folderItem.type === "PDF",
                )
                .filter((folderItem) => {
                  return isSendEmailModal
                    ? filesToSave.every(
                        (savedFile) => folderItem.id !== savedFile.id,
                      )
                    : true;
                })
                .sort(sortFolderItems)
                .sort((a: FolderItem, b: FolderItem) => {
                  return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
                })
                .map((folderItem) => (
                  <>
                    <SelectFromMyDocsTableRow
                      item={{
                        ...folderItem,
                        deletedAt: "",
                        parentFolderId: folderItem.parentFolderId || "",
                        childrenCount: folderItem.childrenCount,
                      }}
                      isOpened={openedFolders.includes(folderItem.id)}
                      onOpenFolder={handleOpenFolder}
                      indent={(el.folder.parentsCount || 1) * 2}
                    />
                    {openedFolders.includes(folderItem.id) &&
                      renderSubFolders(
                        el.childFolders.filter(
                          (childFolder) =>
                            folderItem.id === childFolder.folder.id,
                        ),
                      )}
                  </>
                ))}
            </div>
          </Fragment>,
        ];
      }
      return <></>;
    });
  };

  return (
    <div className={styles.SelectFromMyDocs}>
      {isSearch ? (
        <h2 className={styles.title}>
          {filteredData.length
            ? t("results", { count: filteredData.length })
            : t("noResults")}
        </h2>
      ) : (
        <h2 className={styles.title}>{t("title")}</h2>
        // <BreadcrumbsDocs
        //   className={styles.title}
        //   sliceSource="requestSigning"
        // />
      )}
      <header className={cs([styles.tr, styles.header])}>
        <HeaderCell
          onSort={
            isSearch ? () => handleSort("name") : () => handleDeepSort("name")
          }
          isSorted={sorting.name}
          name={tG("name")}
        />
        <HeaderCell name={t("type")} />
        <HeaderCell name={t("attributes")} />
        <HeaderCell
          name={t("modificationDate")}
          onSort={
            isSearch
              ? () => handleSort("createdAt")
              : () => handleDeepSort("createdAt")
          }
          isSorted={sorting.createdAt}
        />
      </header>

      {isSearch && searchValue
        ? filteredData.map((item) => (
            <SelectFromMyDocsTableRow
              key={item.id}
              item={{
                ...item,
                deletedAt: "",
                parentFolderId: item.parentFolderId || "",
              }}
              isOpened={openedFolders.includes(item.id)}
              onOpenFolder={handleOpenFolder}
              indent={0}
              isSearch
            />
          ))
        : newData[0]?.items
            .filter(
              (folderItem) =>
                isSendEmailModal ||
                folderItem.type === "FOLDER" ||
                folderItem.type === "PDF",
            )
            .filter((folderItem) => {
              return isSendEmailModal
                ? filesToSave.every(
                    (savedFile) => folderItem.id !== savedFile.id,
                  )
                : true;
            })
            .sort(sortFolderItems)
            .sort((a: FolderItem, b: FolderItem) => {
              return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
            })
            .map((item) => {
              return (
                <Fragment key={item.id}>
                  <SelectFromMyDocsTableRow
                    item={{
                      ...item,
                      deletedAt: "",
                      parentFolderId: item.parentFolderId || "",
                    }}
                    isOpened={openedFolders.includes(item.id)}
                    onOpenFolder={handleOpenFolder}
                    indent={0}
                  />

                  {openedFolders.includes(item.id) &&
                    renderSubFolders(
                      newData[0]?.childFolders.filter(
                        (childFolder) => item.id === childFolder.folder.id,
                      ),
                    )}
                </Fragment>
              );
            })}
      <NoTableData
        isSearch={!!searchValue}
        isNoFilteredData={filteredData?.length === 0}
        isNoData={openedFolderData && initialData?.length === 0}
        isRequestSigning
      />
    </div>
  );
};
