import { FC, useState, ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, contacts, settings } from "store";
import { createContact, updateContact } from "store/contacts/thunks";

import { Input, Button, Spinner } from "components/UI";
import { palette, validateEmail, pushGaEvent } from "utils";
import { useEffectOnce } from "hooks";
import { Contacts } from "api";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type ContactModalProps = {
  onClose: () => void;
};

export const ContactModal: FC<ContactModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(settings);
  const { editableContact } = useAppSelector(contacts);
  const { t } = useTranslation("Modals", { keyPrefix: "Contact" });
  const { t: tG } = useTranslation("General");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getContact = useCallback(async () => {
    if (editableContact) {
      const res = await Contacts.getContact(editableContact);
      res.firstName && setFirstName(res.firstName);
      res.lastName && setLastName(res.lastName);
      res.email && setEmail(res.email);
    }
  }, [editableContact]);

  useEffectOnce(() => {
    getContact();
  });

  const checkValidity = () => {
    const emailValidationErrors = email && validateEmail(email);
    if (emailValidationErrors.length > 0) {
      setIsErrorEmail(true);
    }
  };

  const handleChangeName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 100) return;
    target.name === "firstName"
      ? setFirstName(target.value)
      : setLastName(target.value);
  };

  const handleClearName = (nameType: "firstName" | "lastName") => {
    nameType === "firstName" ? setFirstName("") : setLastName("");
  };

  const handleChangeEmail = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value?.length > 100 || email === user?.email) return;
    setIsErrorEmail(false);
    setEmail(value);
  };

  const handleClearEmail = () => {
    setEmail("");
    setIsErrorEmail(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    checkValidity();
    if (!isErrorEmail) {
      if (editableContact) {
        dispatch(
          updateContact({
            id: editableContact,
            data: { firstName, lastName, email },
          }),
        );
        pushGaEvent({
          name: "Rename contact",
          action: "Contact rename from the contact list",
        });
      } else {
        dispatch(createContact({ firstName, lastName, email }));
        pushGaEvent({
          name: "Create contact",
          action: "Contact creation from contact tab",
        });
      }
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.ContactModal}>
      <ModalHeader
        onClose={onClose}
        title={editableContact ? t("editContact") : t("newContact")}
        className={styles.header}
      />
      <div className={styles.inputGroup}>
        <Input
          className={styles.input}
          onChange={handleChangeName}
          value={firstName}
          name="firstName"
          isClearButton
          onClear={() => handleClearName("firstName")}
          placeholder={tG("firstName")}
          label={tG("firstName")}
          isRequired
        />
        <Input
          className={styles.input}
          onChange={handleChangeName}
          value={lastName}
          name="lastName"
          isClearButton
          onClear={() => handleClearName("lastName")}
          placeholder={tG("lastName")}
          label={tG("lastName")}
          isRequired
        />
      </div>
      <Input
        className={styles.input}
        onChange={handleChangeEmail}
        onBlur={checkValidity}
        value={email}
        name="email"
        onClear={handleClearEmail}
        placeholder={tG("emailPlaceholder")}
        error={isErrorEmail ? t("emailError") : ""}
        label={tG("emailLabel")}
        disabled={email === user?.email}
        isClearButton={email !== user?.email}
        isRequired
      />
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={
            isLoading ? (
              <Spinner color={palette.white} />
            ) : (
              t("submitButtonTitle")
            )
          }
          onClick={handleSubmit}
          isDisabled={!firstName || !lastName || !email}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
