import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  SharedDocuments,
  IDeleteDraftsRequest,
  IGetSentDocsRequest,
  IGetInboxDocsRequest,
  IRejectInboxDocRequest,
  IUndoDeleteDraftRequest,
  IGetDocRequest,
} from "api";

export const getSentDocs = createAsyncThunk(
  "signings/getSentDocs",
  async (data: IGetSentDocsRequest) => SharedDocuments.getSentDocs(data),
);

export const getSentDoc = createAsyncThunk(
  "signings/getSentDoc",
  async (data: IGetDocRequest) => SharedDocuments.getSentDoc(data),
);

export const getInboxDocs = createAsyncThunk(
  "signings/getInboxDocs",
  async (data: IGetInboxDocsRequest) => SharedDocuments.getInboxDocs(data),
);

export const getInboxDoc = createAsyncThunk(
  "signings/getInboxDoc",
  async (data: IGetDocRequest) => SharedDocuments.getInboxDoc(data),
);

export const getDrafts = createAsyncThunk("signings/getDrafts", async () =>
  SharedDocuments.getDrafts({}),
);

export const deleteDrafts = createAsyncThunk(
  "signings/deleteDrafts",
  async (data: IDeleteDraftsRequest) => SharedDocuments.deleteDraft(data),
);

export const undoDeleteDraft = createAsyncThunk(
  "signings/undoDeleteDraft",
  async (data: IUndoDeleteDraftRequest) =>
    SharedDocuments.undoDeleteDraft(data),
);

export const deleteSent = createAsyncThunk(
  "signings/deleteSent",
  async (id: string) => SharedDocuments.deleteSentDoc(id),
);

export const undoDeleteSent = createAsyncThunk(
  "signings/undoDeleteSent",
  async (id: string) => SharedDocuments.undoDeleteSent(id),
);

export const cancelRequest = createAsyncThunk(
  "signings/deleteSent",
  async (id: string) => SharedDocuments.cancelSentDoc(id),
);

export const getDraft = createAsyncThunk(
  "signings/getDraft",
  async (id: string) => SharedDocuments.getDraft({ id }),
);

export const deleteInbox = createAsyncThunk(
  "signings/deleteInbox",
  async (id: string) => SharedDocuments.deleteInboxDoc(id),
);

export const undoDeleteInbox = createAsyncThunk(
  "signings/undoDeleteInbox",
  async (id: string) => SharedDocuments.undoDeleteInbox(id),
);

export const rejectRequest = createAsyncThunk(
  "signings/rejectRequest",
  async (data: IRejectInboxDocRequest) => SharedDocuments.rejectInboxDoc(data),
);

export const undoMoveDocument = createAsyncThunk(
  "signings/undoMoveDocument",
  async (id: string) => SharedDocuments.undoMoveDocument(id),
);

export const getGuestRecipientDoc = createAsyncThunk(
  "signings/getGuestRecipientDoc",
  async (id: string) => SharedDocuments.getGuestRecipientDoc({ id }),
);

export const getGuestOwnerDoc = createAsyncThunk(
  "signings/getGuestOwnerDoc",
  async (id: string) => SharedDocuments.getGuestOwnerDoc({ id }),
);

export const rejectGuestRequest = createAsyncThunk(
  "signings/rejectGuestRequest",
  async (data: IRejectInboxDocRequest) =>
    SharedDocuments.rejectGuestRecipientDoc(data),
);

export const getSigningsMetadata = createAsyncThunk(
  "signings/getSigningsMetadata",
  async (id: string) => SharedDocuments.getCertificationInfo(id),
);

export const getGuestSigningsMetadata = createAsyncThunk(
  "signings/getGuestSigningsMetadata",
  async (id: string) => SharedDocuments.getGuestCertificationInfo(id),
);
