import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";

import {
  useAppSelector,
  useAppDispatch,
  signings,
  requestSigning,
  dashboard,
} from "store";
import { setIsDeleteDraftModal, setUsedColors } from "store/requestSigning";
import { getDraft, deleteDrafts, undoDeleteDraft } from "store/signings/thunks";
import { setIsDetailedViewMenu } from "store/signings";
import { setIsLoading, setSearch } from "store/dashboard";
import { getContacts } from "store/contacts/thunks";

import {
  BreadcrumbsRequestSigning,
  RequestSigningCloseModal,
  AreYouSureModal,
} from "components";
import { Button, Modal, Icon } from "components/UI";
import { PATHES } from "constants/pathes";

import { useSigningsRequest, useEffectOnce } from "hooks";
import { sleep, toastUndo } from "utils";

import styles from "./styles.module.scss";

export const RequestSigning = () => {
  const { t } = useTranslation("Pages", {
    keyPrefix: "RequestSigning",
  });
  const { t: tD } = useTranslation("Table", {
    keyPrefix: "SigningsDraft.Modals",
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDetailedViewMenu } = useAppSelector(signings);
  const { isLoading } = useAppSelector(dashboard);
  const { isDeleteDraftModal, editedDraft, editedContact } =
    useAppSelector(requestSigning);
  const { handleUpdateDraft, draftChanged, handleCloseRequest } =
    useSigningsRequest();
  const [isCloseModal, setIsCloseModal] = useState<boolean>(false);
  const { draftId } = useParams();

  useEffect(() => {
    if (draftId) {
      dispatch(setUsedColors([]));
      localStorage.removeItem("instantJSON");
      dispatch(getDraft(draftId));
    }
  }, [dispatch, draftId]);

  const showLoader = useCallback(async () => {
    if (draftId) {
      dispatch(setIsLoading(true));
      await sleep(1500);
      dispatch(setIsLoading(false));
    }
  }, [dispatch, draftId]);

  useEffect(() => {
    showLoader();
  }, [showLoader]);

  useEffectOnce(() => {
    dispatch(getContacts());
  });

  const getStep = () => {
    if (pathname.startsWith(PATHES.SIGNINGS_AREA)) {
      return 2;
    }
    if (pathname.startsWith(PATHES.EMAIL_DETAILS)) {
      return 3;
    }
    return 1;
  };

  const handleCloseSubmit = async () => {
    await handleUpdateDraft(getStep());
    handleCloseRequest();
    localStorage.removeItem("instantJSON");
    navigate(PATHES.DRAFT);
  };

  const handleCloseCancel = () => {
    handleCloseRequest();
    localStorage.removeItem("instantJSON");
    navigate(PATHES.DRAFT);
  };

  const handleShowCloseModal = async () => {
    await sleep(100);
    if (editedContact) return;
    setIsCloseModal(true);
  };

  const handleHideCloseModal = () => {
    setIsCloseModal(false);
  };

  const checkForChanges = () => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);

    const annotationsChanged =
      editedDraft?.instantJson?.annotations?.some(
        (el: any) =>
          parsedInstant?.annotations?.some((item: any) => item.id !== el.id),
      ) ||
      parsedInstant?.annotations?.length !==
        editedDraft?.instantJson?.annotations?.length;
    if ((draftChanged || annotationsChanged) && getStep() !== 3) {
      handleShowCloseModal();
    } else {
      handleCloseCancel();
    }
  };

  const handleOpenMenu = () => {
    dispatch(setIsDetailedViewMenu(true));
  };

  const handleCloseMenu = () => {
    dispatch(setIsDetailedViewMenu(false));
  };

  const handleOpenAreYouSureDeleteDraft = () => {
    if (draftId) {
      dispatch(setIsDeleteDraftModal(true));
      handleCloseMenu();
    }
  };

  const handleConfirmAreYouSureDeleteDraft = async () => {
    if (draftId) {
      const text = tD("deleteToastText");
      const textUndo = tD("deleteToastTextUndo");

      dispatch(setIsDeleteDraftModal(false));
      handleCloseCancel();
      await dispatch(deleteDrafts({ documentIds: [draftId] }));
      toastUndo({
        id: draftId,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDeleteDraft({ documentIds: [draftId] }));
        },
      });
      dispatch(setSearch(""));
    }
  };

  const handleCloseAreYouSureDeleteDraft = () => {
    dispatch(setIsDeleteDraftModal(false));
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={styles.RequestSigning}>
      <Modal
        isShowed={isCloseModal}
        onClose={handleHideCloseModal}
        styleWrap={{ zIndex: 110 }}
      >
        <RequestSigningCloseModal
          onClose={handleHideCloseModal}
          onSubmit={handleCloseSubmit}
          onCancel={handleCloseCancel}
        />
      </Modal>
      <Modal
        isShowed={isDeleteDraftModal}
        onClose={handleCloseAreYouSureDeleteDraft}
        styleWrap={{ zIndex: 110 }}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDeleteDraft}
          onSubmit={handleConfirmAreYouSureDeleteDraft}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <header className={styles.header}>
        <Button
          variant="textBlack"
          title={t("closeButtonTitle")}
          size="sm"
          onClick={checkForChanges}
          iconStart="close"
          iconStartType="stroke"
          className={styles.closeButton}
        />
        <div className={styles.breadcrumbs}>
          <BreadcrumbsRequestSigning />
        </div>
        <div className={styles.rightSide}>
          {[PATHES.SIGNINGS_AREA, PATHES.EMAIL_DETAILS].some((word) =>
            pathname.startsWith(word),
          ) && (
            <OutsideClickHandler
              onOutsideClick={handleCloseMenu}
              display="flex"
            >
              <div className={styles.label}>{t("label")}</div>
              <div className={styles.action} onClick={handleOpenMenu}>
                <Icon name="three-dots-horizontal" />
                {isDetailedViewMenu && (
                  <Button
                    title={t("deleteButtonTitle")}
                    size="md"
                    variant="secondary"
                    onClick={handleOpenAreYouSureDeleteDraft}
                    iconStart="trash"
                    iconStartType="stroke"
                    className={styles.deleteButton}
                  />
                )}
              </div>
            </OutsideClickHandler>
          )}
        </div>
      </header>
      <Outlet />
    </div>
  );
};
