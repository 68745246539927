import { useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, trashBin, folders } from "store";
import {
  setOpenedDocument,
  setIsDeleteItemsModal,
  setIsRestoreItemsModal,
  setSoftDeletedItems,
  setItemsToDelete,
  setItemsToRestore,
  setCurrentFolder as setCurrentTrashFolder,
} from "store/trashBin";
import { setOpenedDocument as setOpenedDashboardDocument } from "store/dashboard";
import { setCurrentFolder } from "store/folders";
import {
  undoDocument as undoTrashDocument,
  getFolder,
} from "store/trashBin/thunks";
import { undoDocument } from "store/folders/thunks";
import { TrashBin } from "api";

import { Button, Modal } from "components/UI";
import { AreYouSureModal } from "components";

import { cs, toastUndo, sleep } from "utils";
import { useActions, useEffectOnce } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const TrashDocHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("TrashBin");
  const { t: tM } = useTranslation("Table", {
    keyPrefix: "TrashBin.Modals",
  });
  const {
    openedDocument,
    openedDocument: { id, url, name, type, daysLeftToHardDeletion },
  } = useAppSelector(trashBin);
  const {
    handleRestore,
    handleHardDelete,
    handleHardDeleteSuccess,
    handleRestoreSuccess,
  } = useActions();
  const { isDeleteItemsModal, isRestoreItemsModal, currentFolder } =
    useAppSelector(trashBin);
  const { rootFolder } = useAppSelector(folders);
  const navigate = useNavigate();
  const { trashDocId } = useParams();
  const { pathname } = useLocation();
  const isDocCertified = pathname.includes("certified");

  const getDocument = useCallback(async () => {
    if (trashDocId) {
      const res = isDocCertified
        ? await TrashBin.getSignedPDFDocument(trashDocId)
        : await TrashBin.getPDFDocument(trashDocId);
      if (res?.document) {
        dispatch(setOpenedDocument(res?.document));
      }
    }
  }, [dispatch, trashDocId, isDocCertified]);

  useEffectOnce(() => {
    getDocument();
  });

  const handleCloseDocument = async () => {
    if (openedDocument) {
      const parents = await TrashBin.getFolderParents(openedDocument?.id);
      const parentFolderid = parents?.parents && parents?.parents[0]?.id;
      parentFolderid && dispatch(setCurrentTrashFolder(parentFolderid));
    }
    dispatch(setOpenedDocument({ url: "", id: "", name: "" }));
    navigate(PATHES.TRASH);
  };

  const handleRestoreDocument = () => {
    dispatch(setItemsToRestore([id]));
    handleRestore();
  };

  const handleDeleteDocument = () => {
    dispatch(setItemsToDelete([id]));
    handleHardDelete();
  };

  const handleCloseAreYouSureDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureDelete = async () => {
    dispatch(setIsDeleteItemsModal(false));
    const isDeleted = await handleHardDeleteSuccess();
    const text = tM("confirmDeleteFileToast");
    const textUndo = tM("confirmDeleteFileToastUndo");
    handleCloseDocument();
    await sleep(100);
    if (isDeleted && id) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoTrashDocument({ folderIds: [id] }));
          dispatch(getFolder({ id: currentFolder }));
        },
      });
    }
  };

  const handleCloseAreYouSureRestore = () => {
    dispatch(setIsRestoreItemsModal(false));
  };

  const handleConfirmAreYouSureRestore = async () => {
    const isRestored = await handleRestoreSuccess();
    const text = tM("confirmRestoreFileToast");
    const textUndo = tM("confirmRestoreFileToastUndo");

    const handleRevert = () => {
      type === "PDF"
        ? navigate(`${PATHES.TRASH_DOCUMENT}/${id}`)
        : navigate(`${PATHES.TRASH_DOCUMENT}/certified/${id}`);
    };

    handleCloseAreYouSureRestore();
    rootFolder && dispatch(setCurrentFolder(rootFolder.id));
    dispatch(setOpenedDashboardDocument({ id, url, name }));
    dispatch(setOpenedDocument({ url: "", id: "", name: "" }));
    type === "PDF"
      ? navigate(`${PATHES.DETAILED_VIEW}/${id}`)
      : navigate(`${PATHES.DETAILED_VIEW}/certified/${id}`);
    await sleep(100);
    if (isRestored) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDocument({ folderIds: [id] }));
          dispatch(getFolder({ id: currentFolder }));
          handleRevert();
        },
      });
    }
  };

  const labelTextNextDays =
    daysLeftToHardDeletion === 1
      ? t("labelTextOneDay", {
          count: daysLeftToHardDeletion,
        })
      : t("labelTextFewDay", {
          count: daysLeftToHardDeletion,
        });

  return (
    <nav className={styles.TrashDocHeader}>
      <Modal
        isShowed={isDeleteItemsModal}
        onClose={handleCloseAreYouSureDelete}
        className={styles.modal}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDelete}
          onSubmit={handleConfirmAreYouSureDelete}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <Modal
        isShowed={isRestoreItemsModal}
        onClose={handleCloseAreYouSureRestore}
        className={styles.modal}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureRestore}
          onSubmit={handleConfirmAreYouSureRestore}
          info={t("areYouSureRestoreInfo")}
          confirmButtonTitle={t("areYouSureRestoreConfirmButtonTitle")}
          isSubmitPositive
        />
      </Modal>
      <div className={styles.inner}>
        <Button
          title={t("backButtonTitle")}
          size="sm"
          variant="textBlack"
          onClick={handleCloseDocument}
          iconStart="chevron-left-empty"
          iconStartType="stroke"
          className={styles.backButton}
        />
        <div className={styles.info}>
          <h2 className={styles.title}>{name}</h2>
          <p
            className={cs([
              styles.deleteText,
              daysLeftToHardDeletion === 0 && styles.active,
            ])}
          >
            {daysLeftToHardDeletion === 0
              ? t("labelTextToday")
              : labelTextNextDays}
          </p>
        </div>
        <div className={styles.actionButtons}>
          <Button
            title={t("restoreButtonTitle")}
            size="md"
            variant="primary"
            onClick={handleRestoreDocument}
            iconStart="restore"
            iconStartType="stroke"
            className={styles.actionButton}
          />
          <Button
            title={t("deleteButtonTitle")}
            size="md"
            variant="secondary"
            onClick={handleDeleteDocument}
            iconStart="trash"
            iconStartType="stroke"
            className={styles.actionButton}
          />
        </div>
      </div>
    </nav>
  );
};
