import { FC, useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useParams, useNavigate, Link } from "react-router-dom";
import Transition, {
  TransitionStatus,
} from "react-transition-group/Transition";

import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  signings,
  folders,
  signatures,
} from "store";

import {
  setIsDetailedViewMenu,
  setInboxDoc,
  setSentDoc,
  setIsInboxRejectRequestModal,
  setIsMovingItemsModal,
  setGuestRecipientDoc,
  setGuestOwnerDoc,
  setIsDeleteInboxItemsModal,
  setIsSentCancelRequestModal,
  setIsDeleteSentItemsModal,
  setIsDocumentVoided,
  setIsDeleteDocUpdated,
  // setIsSigningStarted,
} from "store/signings";
import {
  setRenameModalItem,
  setDestinationFolder,
  setCurrentContextFolder,
} from "store/folders";
import {
  getInboxDoc,
  getSentDoc,
  getGuestRecipientDoc,
  getGuestOwnerDoc,
  cancelRequest,
  getSigningsMetadata,
  getGuestSigningsMetadata,
} from "store/signings/thunks";
import { getSignatures, getGuestSignatures } from "store/signatures/thunks";
import { setIsViewHistoryModal, setOpenedDocMetadata } from "store/dashboard";
import {
  setEditedDraft,
  setRecipients,
  setFilesToSave,
} from "store/requestSigning";
import { ThemeContext } from "providers";

import { Button, Icon, Modal } from "components/UI";
import {
  DocIDCopy,
  RenameModal,
  RejectSigningModal,
  MovingItemsModal,
  CreateFolderModal,
  AreYouSureModal,
  ConfirmModal,
  ActionsHistoryModal,
  MetadataModal,
} from "components";

import { PATHES } from "constants/pathes";
import { useActions } from "hooks";
import { SIGNING_STATUSES } from "types";
import { cs, palette, toastSuccess, opacityTransitionConfig } from "utils";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import { RecipientsWidget } from "../RecipientsWidget";
import { RejectionWidget } from "../RejectionWidget";
import { SignatureWidget } from "../SignatureWidget";

import styles from "./styles.module.scss";

type RecipientSigningHeaderProps = {
  requiredSigned: number;
  optionalSigned: number;
  totalOptionalWidgets: number;
  totalRequiredWidgets: number;
  isSigningStarted: boolean;
  isOwner?: boolean;
  isBackup?: boolean;
  onChangeSignature?: (isInitial?: boolean) => void;
};

export const RecipientSigningHeader: FC<RecipientSigningHeaderProps> = ({
  requiredSigned,
  optionalSigned,
  totalOptionalWidgets,
  totalRequiredWidgets,
  isSigningStarted,
  isOwner,
  isBackup,
  onChangeSignature = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RecipientSigningHeader");
  const { t: tI } = useTranslation("Table", {
    keyPrefix: "SigningsInbox.Modals",
  });
  const { t: tS } = useTranslation("Table", {
    keyPrefix: "SigningsSent.Modals",
  });
  const { t: tGDR } = useTranslation("GuestDocReady");
  const { t: tG } = useTranslation("General");
  const { t: tD } = useTranslation("DetailedViewHeader");
  const { inboxDocId, sentDocId, linkId, ownerLinkId } = useParams();
  const navigate = useNavigate();
  const mobileMenuRef = useRef(null);
  const mobileMenuClosedRef = useRef(null);
  const { isMobile } = useContext(ThemeContext);
  const { dateFormat, timeFormat, isViewHistoryModal, openedDocMetadata } =
    useAppSelector(dashboard);
  const {
    isDetailedViewMenu,
    inboxDoc,
    sentDoc,
    isInboxRejectRequestModal,
    isMovingItemsModal,
    guestRecipientDoc,
    guestOwnerDoc,
    isDeleteInboxItemsModal,
    sentToCancelRequest,
    isSentCancelRequestModal,
    isDeleteSentItemsModal,
    isDocumentVoided,
    isGuestDocError,
  } = useAppSelector(signings);
  const { renameModalItem } = useAppSelector(folders);
  const { isDeleted, isCreated, isUpdated } = useAppSelector(signatures);
  const isAllRequiredSigned =
    totalRequiredWidgets > 0 && totalRequiredWidgets === requiredSigned;
  const isAllOptionalSigned =
    totalOptionalWidgets > 0 && totalOptionalWidgets === optionalSigned;
  let docToSign = inboxDoc;
  if (sentDocId) {
    docToSign = sentDoc;
  } else if (linkId) {
    docToSign = guestRecipientDoc;
  } else if (ownerLinkId) {
    docToSign = guestOwnerDoc;
  }

  const { id, name, receivedAt, sentAt, sendBy, status, inProgressState } =
    docToSign || {};
  const docId = inboxDocId || sentDocId || linkId || ownerLinkId;
  const {
    handleDeleteInbox,
    handleRejectRequest,
    handleRenameInboxDocument,
    handleDeleteInboxSuccess,
    handleDeleteSent,
    handleCancelRequset,
    handleDeleteSentSuccess,
  } = useActions(docId);
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
  const [isMetadataModal, setIsMetadataModal] = useState<boolean>(false);
  const isGuest = !!(linkId || ownerLinkId);

  useEffect(() => {
    if (inboxDocId) {
      dispatch(getInboxDoc({ id: inboxDocId, isBackupFile: isBackup }));
    }
  }, [dispatch, inboxDocId, isBackup]);

  useEffect(() => {
    if (sentDocId) {
      dispatch(getSentDoc({ id: sentDocId, isBackupFile: isBackup }));
    }
  }, [dispatch, sentDocId, isBackup]);

  useEffect(() => {
    if (linkId) {
      dispatch(getGuestRecipientDoc(linkId));
    }
  }, [dispatch, linkId]);

  useEffect(() => {
    if (ownerLinkId) {
      dispatch(getGuestOwnerDoc(ownerLinkId));
    }
  }, [dispatch, ownerLinkId]);

  useEffect(() => {
    if (linkId) {
      dispatch(getGuestSignatures(linkId));
    } else if (inboxDocId) {
      dispatch(getSignatures());
    }
  }, [dispatch, isDeleted, isCreated, isUpdated, linkId, inboxDocId]);

  useEffect(() => {
    if (openedDocMetadata?.documentId) {
      setIsMetadataModal(true);
    }
  }, [openedDocMetadata?.documentId]);

  useEffect(() => {
    if (isGuestDocError) {
      navigate(PATHES.GUEST_DOCUMENT_NOT_FOUND);
    }
  }, [isGuestDocError, navigate]);

  const handleCloseDocument = async () => {
    localStorage.removeItem("instantJSON");
    sessionStorage.removeItem("IsSigningStarted");
    sessionStorage.removeItem("placeholdersUpdated");
    dispatch(setInboxDoc(null));
    dispatch(setSentDoc(null));
    dispatch(setGuestRecipientDoc(null));
    dispatch(setGuestOwnerDoc(null));
    dispatch(
      setRecipients([
        { id: "", firstName: "", lastName: "", email: "", error: "" },
      ]),
    );
    dispatch(setEditedDraft(null));
    dispatch(setFilesToSave([]));
    inboxDocId && !isOwner && navigate(PATHES.INBOX);
    (sentDocId || isOwner) && navigate(PATHES.SENT);
  };

  const handleOpenMenu = () => {
    dispatch(setIsDetailedViewMenu(true));
    // setIsMenu(true);
  };

  const handleCloseMenu = () => {
    dispatch(setIsDetailedViewMenu(false));
    // setIsMenu(false);
  };

  const handleDeleteInboxDocument = () => {
    if (id) {
      handleDeleteInbox();
      handleCloseMenu();
    }
  };

  const handleDeleteSentDocument = () => {
    if (id) {
      handleDeleteSent();
      handleCloseMenu();
    }
  };

  const handleCancelDocument = () => {
    if (id) {
      handleCancelRequset();
      handleCloseMenu();
    }
  };

  const handleCloseAreYouSureCancelRequest = () => {
    dispatch(setIsSentCancelRequestModal(false));
  };

  const handleConfirmAreYouSureCancelRequest = async () => {
    await Promise.all(
      sentToCancelRequest.map((el) => dispatch(cancelRequest(el))),
    );
    handleCloseAreYouSureCancelRequest();
    handleCloseMenu();
    handleCloseDocument();
    toastSuccess(tS("toastCancelRequestSuccess"));
  };

  const handleRejectDocument = () => {
    if (id) {
      handleRejectRequest();
      handleCloseMenu();
    }
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
  };

  const handleCloseRejectModal = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleSubmitReject = () => {
    dispatch(setIsInboxRejectRequestModal(false));
    localStorage.removeItem("instantJSON");
    dispatch(setInboxDoc(null));
    dispatch(setGuestRecipientDoc(null));
    navigate(isOwner ? PATHES.SENT_ALL : PATHES.INBOX_VOIDED);
    toastSuccess(t("toastRejectSuccess"));
  };

  const handleCloseMoveModal = () => {
    dispatch(setIsMovingItemsModal(false));
    dispatch(setDestinationFolder(null));
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseAreYouSureMoveToBinInbox = () => {
    dispatch(setIsDeleteInboxItemsModal(false));
  };

  const handleConfirmAreYouSureMoveToBinInbox = async () => {
    handleCloseDocument();
    await handleDeleteInboxSuccess(() => {});
    dispatch(setIsDeleteDocUpdated(true));
    handleCloseAreYouSureMoveToBinInbox();
  };

  const handleCloseAreYouSureMoveToBinSent = () => {
    dispatch(setIsDeleteSentItemsModal(false));
  };
  const handleConfirmAreYouSureMoveToBinSent = async () => {
    handleCloseDocument();
    await handleDeleteSentSuccess(() => {});
    dispatch(setIsDeleteDocUpdated(true));
    handleCloseAreYouSureMoveToBinSent();
  };

  const handleSubmitDocumentVoidModal = () => {
    if (inboxDocId) {
      dispatch(getInboxDoc({ id: inboxDocId }));
    }
    if (linkId) {
      dispatch(getGuestRecipientDoc(linkId));
    }
    dispatch(setIsDocumentVoided(false));
  };

  const handleOpenMetadataModal = async () => {
    if (docId && (inboxDocId || sentDocId)) {
      dispatch(getSigningsMetadata(docId));
    } else if (docId && (linkId || ownerLinkId)) {
      dispatch(getGuestSigningsMetadata(docId));
    }
  };

  const handleCloseMetadataModal = async () => {
    dispatch(setOpenedDocMetadata(null));
    setIsMetadataModal(false);
  };

  const handleChangeSignature = (isInitial?: boolean) => {
    onChangeSignature(isInitial);
    setIsMobileMenu(false);
  };

  const renderDate = () => {
    let dateText = t("received");
    if (status === "COMPLETED") {
      dateText = t("createdOn");
    } else if (sentDocId) {
      dateText = t("sentAt");
    }
    return (
      <p className={styles.date}>
        {dateText}{" "}
        {dayjs(sentDocId ? sentAt : receivedAt).format(
          `${dateFormat}, ${timeFormat}`,
        )}
      </p>
    );
  };

  const renderStatus = () => (
    <>
      {status && (
        <div className={cs([styles.status, styles[status]])}>
          {SIGNING_STATUSES[status]}
          {inProgressState &&
            ` ${inProgressState.signed}/${inProgressState.total}`}
        </div>
      )}
    </>
  );

  const renderSender = () => {
    if (sendBy) {
      return (
        <p className={styles.sender}>
          {t("by")} {sendBy}
        </p>
      );
    }
  };

  if (isMobile) {
    return (
      <>
        <Modal isShowed={isMetadataModal} styleWrap={{ zIndex: 110 }}>
          <MetadataModal onClose={handleCloseMetadataModal} />
        </Modal>
        <Transition
          in={isMobileMenu}
          appear
          mountOnEnter
          unmountOnExit
          timeout={opacityTransitionConfig().timeout}
          nodeRef={mobileMenuRef}
        >
          {(state: TransitionStatus) => (
            <nav
              className={cs([
                styles.RecipientSigningMobileHeader,
                styles.withMenu,
              ])}
              ref={mobileMenuRef}
              style={{
                ...opacityTransitionConfig().defaultStyles,
                ...opacityTransitionConfig().transitionStyles[state],
              }}
            >
              <Modal
                isShowed={!!isViewHistoryModal}
                styleWrap={{ zIndex: 110 }}
              >
                <ActionsHistoryModal
                  onClose={() => dispatch(setIsViewHistoryModal(false))}
                  id={linkId || ownerLinkId || docToSign?.id || ""}
                  docName={docToSign?.name || ""}
                  isGuest={!!(linkId || ownerLinkId)}
                />
              </Modal>
              <header className={styles.header}>
                <Link
                  to="https://www.apostisign.com/"
                  className={styles.mainLogo}
                >
                  <img
                    src={mainLogoBlack}
                    alt="main-logo"
                    width="100"
                    height="26"
                  />
                </Link>

                <Icon
                  name="close"
                  className={cs([styles.menuIcon, styles.withSpace])}
                  color={palette.black}
                  size={18}
                  action={() => setIsMobileMenu(!isMobileMenu)}
                />
              </header>
              <div className={styles.info}>
                <h2 className={styles.title}>{name}</h2>
              </div>
              <div className={styles.info}>
                <div className={styles.statusWrap}>{renderStatus()}</div>
                {renderSender()}
              </div>

              <div className={cs([styles.info, styles.withMenu])}>
                {renderDate()}
                <div className={styles.id}>
                  {t("id")}:{" "}
                  {id && <DocIDCopy id={id} size="sm" width="10rem" />}
                </div>
              </div>
              {docToSign?.status === "REJECTED" && !isGuest && (
                <RejectionWidget isMobileMenu />
              )}
              {docToSign?.status === "NEED_TO_SIGN" && isSigningStarted && (
                <SignatureWidget
                  isMobileMenu
                  onChangeSignature={handleChangeSignature}
                />
              )}
              {(docToSign?.status === "NEED_TO_SIGN"
                ? isSigningStarted
                : true) && <RecipientsWidget isMobileMenu isGuest={isGuest} />}
            </nav>
          )}
        </Transition>
        <Transition
          in={!isMobileMenu}
          appear
          mountOnEnter
          unmountOnExit
          timeout={opacityTransitionConfig().timeout}
          nodeRef={mobileMenuClosedRef}
        >
          {(state: TransitionStatus) => (
            <nav
              className={styles.RecipientSigningMobileHeader}
              ref={mobileMenuRef}
              style={{
                ...opacityTransitionConfig().defaultStyles,
                ...opacityTransitionConfig().transitionStyles[state],
              }}
            >
              <header className={styles.header}>
                <h2 className={styles.title}>{name}</h2>
                <Icon
                  name="burger"
                  className={styles.menuIcon}
                  color={palette.black}
                  size={30}
                  action={() => setIsMobileMenu(!isMobileMenu)}
                />
              </header>
              {isSigningStarted ? (
                <div className={styles.statusFields}>
                  <div className={styles.statusWrap}>
                    <div
                      className={cs([
                        styles.statusField,
                        isAllRequiredSigned && styles.completed,
                      ])}
                    >
                      {t("mandatory")} {requiredSigned}/{totalRequiredWidgets}
                    </div>
                  </div>
                  {totalOptionalWidgets > 0 && (
                    <div className={styles.statusOptionalWrap}>
                      <div
                        className={cs([
                          styles.status,
                          styles.optional,
                          isAllOptionalSigned && styles.completed,
                        ])}
                      >
                        {t("optional")} {optionalSigned}/{totalOptionalWidgets}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className={cs([styles.info, styles.smallMarginTop])}>
                    <div className={styles.statusWrap}>{renderStatus()}</div>
                    {renderSender()}
                  </div>
                  {status === "COMPLETED" && (
                    <div
                      className={styles.certified}
                      onClick={handleOpenMetadataModal}
                    >
                      <div className={styles.content}>
                        <Icon name="certification" />
                        <p>{tD("certifiedDocument")}</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </nav>
          )}
        </Transition>
      </>
    );
  }

  return (
    <nav className={styles.RecipientSigningHeader}>
      <Modal
        isShowed={isDeleteInboxItemsModal}
        onClose={handleCloseAreYouSureMoveToBinInbox}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureMoveToBinInbox}
          onSubmit={handleConfirmAreYouSureMoveToBinInbox}
          info={tI("areYouSureMoveToBin")}
          confirmButtonTitle={tI("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isDeleteSentItemsModal}
        onClose={handleCloseAreYouSureMoveToBinSent}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureMoveToBinSent}
          onSubmit={handleConfirmAreYouSureMoveToBinSent}
          info={tS("areYouSureMoveToBin")}
          confirmButtonTitle={tS("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal
          onClose={handleCloseRenameModal}
          inboxOldName={inboxDoc?.name}
          sentOldName={sentDoc?.name}
          refreshState={() =>
            (inboxDocId && dispatch(getInboxDoc({ id: inboxDocId }))) ||
            (sentDocId && dispatch(getSentDoc({ id: sentDocId })))
          }
        />
      </Modal>
      <Modal isShowed={isInboxRejectRequestModal}>
        <RejectSigningModal
          onClose={handleCloseRejectModal}
          onSubmit={handleSubmitReject}
          isGuest={!!(linkId || ownerLinkId)}
        />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          isInbox={!!inboxDocId}
          isSent={!!sentDocId}
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal
        isShowed={isSentCancelRequestModal}
        onClose={handleCloseAreYouSureCancelRequest}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureCancelRequest}
          onSubmit={handleConfirmAreYouSureCancelRequest}
          info={tS("areYouSureCancelRequestInfo")}
          confirmButtonTitle={tS("areYouSureCancelRequestConfirmButtonTitle")}
          cancelButtonTitle={tS("areYouSureCancelRequestCancelButtonTitle")}
        />
      </Modal>
      <Modal isShowed={isDocumentVoided}>
        <ConfirmModal
          title={tGDR("titleVoid")}
          info={tGDR("voidInfo")}
          submitTitle={tG("okay")}
          submitAction={handleSubmitDocumentVoidModal}
        />
      </Modal>
      <Modal isShowed={!!isViewHistoryModal} styleWrap={{ zIndex: 110 }}>
        <ActionsHistoryModal
          onClose={() => dispatch(setIsViewHistoryModal(false))}
          id={linkId || ownerLinkId || docToSign?.id || ""}
          docName={docToSign?.name || ""}
          isGuest={!!(linkId || ownerLinkId)}
        />
      </Modal>
      <Modal isShowed={isMetadataModal}>
        <MetadataModal onClose={handleCloseMetadataModal} />
      </Modal>
      <div className={styles.inner}>
        {linkId || ownerLinkId ? (
          <Link to="https://www.apostisign.com/" className={styles.mainLogo}>
            <img src={mainLogoBlack} alt="main-logo" width="91" height="24" />
          </Link>
        ) : (
          <Button
            title={t("backButtonTitle")}
            size="sm"
            variant="textBlack"
            onClick={handleCloseDocument}
            iconStart="chevron-left-empty"
            iconStartType="stroke"
            className={styles.backButton}
          />
        )}
        <div className={styles.info}>
          <h2
            className={styles.title}
            onClick={() => !linkId && !isBackup && handleRenameInboxDocument()}
          >
            {name}{" "}
            {status === "COMPLETED" && inboxDocId && !isBackup && (
              <Icon
                name="edit"
                className={styles.editNameIcon}
                color={palette.grey90}
                size={16}
              />
            )}
          </h2>
          <div className={styles.docInfo}>
            {renderDate()}
            {status !== "COMPLETED" && renderSender()}
            <div className={styles.id}>
              {t("id")}: {id && <DocIDCopy id={id} size="sm" />}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          {renderStatus()}
          {(inboxDocId || sentDocId || status === "NEED_TO_SIGN") &&
            !isBackup && (
              <OutsideClickHandler onOutsideClick={handleCloseMenu}>
                <div onClick={handleOpenMenu} className={styles.actionButton}>
                  <Icon
                    name="three-dots-horizontal"
                    className={styles.action}
                  />
                  {isDetailedViewMenu && (
                    <Button
                      title={
                        status === "NEED_TO_SIGN" || !inboxDocId
                          ? t("rejectButtonTitle")
                          : t("deleteButtonTitle")
                      }
                      size="md"
                      variant="secondary"
                      onClick={
                        status === "NEED_TO_SIGN" || !inboxDocId
                          ? handleRejectDocument
                          : handleDeleteInboxDocument
                      }
                      iconStart="close-in-circle"
                      iconStartType="stroke"
                      className={styles.menuButton}
                    />
                  )}
                  {isDetailedViewMenu && sentDocId && (
                    <Button
                      title={
                        status === "IN_PROGRESS"
                          ? t("canceButtonTitle")
                          : t("deleteButtonTitle")
                      }
                      size="md"
                      variant="secondary"
                      onClick={
                        status === "IN_PROGRESS"
                          ? handleCancelDocument
                          : handleDeleteSentDocument
                      }
                      iconStart="close-in-circle"
                      iconStartType="stroke"
                      className={styles.menuButton}
                    />
                  )}
                </div>
              </OutsideClickHandler>
            )}
        </div>
      </div>
      {status === "COMPLETED" && (
        <div className={styles.certified} onClick={handleOpenMetadataModal}>
          <div className={styles.content}>
            <Icon name="certification" />
            <p>{tD("certifiedDocument")}</p>
          </div>
        </div>
      )}
    </nav>
  );
};
