import { useEffect, useCallback } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import useWebSocket from "react-use-websocket";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Dashboard,
  Account,
  Settings,
  TrashBin,
  Contacts,
  Signings,
  Sent,
  Inbox,
  RequestSigning,
  FileRecipients,
  RequestEmailDetails,
  Subscription,
  SubscriptionPlans,
  SubscriptionPayment,
  SubscriptionPaymentMethod,
} from "pages";
import {
  Sidebar,
  LoaderFullScreen,
  NotFound,
  SigningsSentTable,
  SigningsDraftTable,
  SigningsInboxTable,
  ItemActionErrorModal,
  DetailedViewHeader,
  PdfViewerDetailedView,
  PdfViewerRequestSigning,
  PdfViewerRecipientSigning,
  NameRequiredModal,
  PdfViewerTrashDoc,
  TrashDocHeader,
} from "components";
import { Modal } from "components/UI";

import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  folders,
  trashBin,
  subscription,
} from "store";
import { getRootFolder } from "store/folders/thunks";
import { getUser } from "store/settings/thunks";
import { setIsLoading, setOpenedDocument } from "store/dashboard";

import { PDF, getSession } from "api";
import { PATHES } from "constants/pathes";
import { WS_URL } from "constants/index";
import { sleep } from "utils";
import { useEffectOnce, useWS } from "hooks";

export const Private = () => {
  const { t } = useTranslation("Private");
  const dispatch = useAppDispatch();
  const { sidebarWidth, errorModal } = useAppSelector(dashboard);
  const { isNameRequiredModal } = useAppSelector(subscription);
  const { handleMessage } = useWS();
  const { rootFolder } = useAppSelector(folders);
  const {
    openedDocument: { url: documentTrashURL },
  } = useAppSelector(trashBin);
  const location = useLocation();
  const { pathname, search } = location;

  const queryDocID = pathname.substring(1);
  const { accessToken } = getSession();

  const isNoSidebar = [
    PATHES.REQUEST,
    PATHES.SUBSCRIPTION_PLANS,
    PATHES.SUBSCRIPTION_PAYMENT,
    PATHES.TRASH_DOCUMENT,
  ].some((word) => pathname.startsWith(word));

  useWebSocket(process.env.REACT_APP_WS_URL || WS_URL, {
    queryParams: { Authorization: accessToken },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 5,
    reconnectInterval: 1000,
    onOpen: () => console.log("ws connected"),
    onError: (error) => {},
    onMessage: handleMessage,
  });

  const setDocument = useCallback(async () => {
    try {
      const res = await PDF.getPdfDocument(queryDocID);
      if (res?.document) {
        const { url, name } = res.document;
        dispatch(setOpenedDocument({ id: queryDocID, url, name }));
      }
    } catch (error) {
      console.log("error:", error);
    }
  }, [dispatch, queryDocID]);

  useEffect(() => {
    if (search && queryDocID) {
      setDocument();
    }
  }, [setDocument, search, queryDocID]);

  useEffect(() => {
    if (!rootFolder) {
      dispatch(getRootFolder());
    }
  }, [rootFolder, dispatch]);

  const showLoader = useCallback(async () => {
    dispatch(setIsLoading(true));
    await sleep(1500);
    dispatch(setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    showLoader();
  }, [showLoader]);

  useEffect(() => {
    toast.dismiss({ containerId: "Undo" });
  }, [location]);

  useEffectOnce(() => {
    dispatch(getUser());
  });

  const renderDetailedView = () => (
    <>
      <DetailedViewHeader />
      <PdfViewerDetailedView />
    </>
  );

  const renderTrashView = () => (
    <>
      <TrashDocHeader />
      <PdfViewerTrashDoc />
    </>
  );

  return (
    <>
      <Modal isShowed={!!errorModal}>
        <ItemActionErrorModal />
      </Modal>
      <Modal isShowed={isNameRequiredModal} styleWrap={{ zIndex: 110 }}>
        <NameRequiredModal />
      </Modal>
      {/* {documentURL && (
        <>
          <DocumentSidebar />
          <PdfViewerComponent
            document={documentURL}
            recipient={queryRecipient}
          />
        </>
      )} */}
      <LoaderFullScreen />
      {/* {document && isCustomSignatureModal && <UploadSignature />} */}
      {!documentTrashURL && !isNoSidebar && <Sidebar />}
      <main style={{ marginLeft: `${isNoSidebar ? 0 : sidebarWidth}px` }}>
        <Routes>
          <Route path={PATHES.HOME} element={<Dashboard />} />
          <Route path={PATHES.TRASH} element={<TrashBin />} />
          <Route
            path={PATHES.CONTRACTS}
            element={<div>{t("contracts")}</div>}
          />
          <Route path={PATHES.ACCOUNT} element={<Account />}>
            <Route path={PATHES.SETTINGS} element={<Settings />} />
            <Route path={PATHES.CONTACTS} element={<Contacts />} />
            <Route path={PATHES.SUBSCRIPTION} element={<Subscription />} />
          </Route>
          <Route
            path={PATHES.SUBSCRIPTION_PAYMENT_METHOD}
            element={<SubscriptionPaymentMethod />}
          />
          <Route
            path={PATHES.SUBSCRIPTION_PLANS}
            element={<SubscriptionPlans />}
          />
          <Route
            path={`${PATHES.SUBSCRIPTION_PAYMENT}/:productId?`}
            element={<SubscriptionPayment />}
          />
          <Route
            path={`${PATHES.SUBSCRIPTION_UPDATE_PAYMENT}/:productId?`}
            element={<SubscriptionPayment isUpdate />}
          />
          <Route
            path={`${PATHES.SUBSCRIPTION_UPDATE_PAYMENT_METHOD}`}
            element={<SubscriptionPayment isUpdateMethod />}
          />
          <Route path={PATHES.SIGNINGS} element={<Signings />}>
            <Route path={PATHES.INBOX} element={<Inbox />}>
              <Route
                path={`${PATHES.INBOX_DOCUMENT}/:inboxDocId?`}
                element={<PdfViewerRecipientSigning />}
              />
              <Route
                path={`${PATHES.INBOX_BACKUP_DOCUMENT}/:inboxDocId?`}
                element={<PdfViewerRecipientSigning isBackup />}
              />
              <Route
                path={`${PATHES.INBOX_OWNER_DOCUMENT}/:inboxDocId?`}
                element={<PdfViewerRecipientSigning isOwner />}
              />
              <Route path={PATHES.INBOX_ALL} element={<SigningsInboxTable />} />
              <Route
                path={PATHES.INBOX_NEED_TO_SIGN}
                element={<SigningsInboxTable status="NEED_TO_SIGN" />}
              />
              <Route
                path={PATHES.INBOX_WAITING_FOR_OTHERS}
                element={<SigningsInboxTable status="WAITING_FOR_OTHERS" />}
              />
              <Route
                path={PATHES.INBOX_COMPLETED}
                element={<SigningsInboxTable status="COMPLETED" />}
              />
              <Route
                path={PATHES.INBOX_VOIDED}
                element={<SigningsInboxTable status="CANCELED" />}
              />
              <Route
                path={PATHES.INBOX}
                element={<Navigate to={PATHES.INBOX_ALL} />}
              />
            </Route>
            <Route path={PATHES.SENT} element={<Sent />}>
              <Route
                path={`${PATHES.SENT_DOCUMENT}/:sentDocId?`}
                element={<PdfViewerRecipientSigning />}
              />
              <Route
                path={`${PATHES.SENT_BACKUP_DOCUMENT}/:sentDocId?`}
                element={<PdfViewerRecipientSigning isBackup />}
              />
              <Route path={PATHES.SENT_ALL} element={<SigningsSentTable />} />
              <Route
                path={PATHES.SENT_IN_PROGRESS}
                element={<SigningsSentTable status="IN_PROGRESS" />}
              />
              <Route
                path={PATHES.SENT_COMPLETED}
                element={<SigningsSentTable status="COMPLETED" />}
              />
              <Route
                path={PATHES.SENT_VOIDED}
                element={<SigningsSentTable status="CANCELED" />}
              />
              <Route
                path={PATHES.SENT}
                element={<Navigate to={PATHES.SENT_ALL} />}
              />
            </Route>
            <Route path={PATHES.DRAFT} element={<SigningsDraftTable />} />
            <Route
              path={PATHES.SIGNINGS}
              element={<Navigate to={PATHES.INBOX} />}
            />
          </Route>
          <Route path={PATHES.REQUEST} element={<RequestSigning />}>
            <Route
              path={`${PATHES.FILE_RECIPIENTS}/:draftId?`}
              element={<FileRecipients />}
            />
            <Route
              path={`${PATHES.SIGNINGS_AREA}/:draftId?`}
              element={<PdfViewerRequestSigning />}
            />
            <Route
              path={`${PATHES.EMAIL_DETAILS}/:draftId?`}
              element={<RequestEmailDetails />}
            />
          </Route>

          <Route
            path={`${PATHES.DETAILED_VIEW}/certified/:documentUserID?`}
            element={renderDetailedView()}
          />
          <Route
            path={`${PATHES.DETAILED_VIEW}/:documentUserID?`}
            element={renderDetailedView()}
          />
          <Route
            path={`${PATHES.TRASH_DOCUMENT}/:trashDocId?`}
            element={renderTrashView()}
          />
          <Route
            path={`${PATHES.TRASH_DOCUMENT}/certified/:trashDocId?`}
            element={renderTrashView()}
          />

          <Route
            path={PATHES.SIGN_IN}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.SIGN_UP}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.ENTER_CODE}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.FORGOT_PASSWORD}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.CREATE_PASSWORD}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.TERMS}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route
            path={PATHES.PRIVACY_POLICY}
            element={<Navigate to={PATHES.HOME} replace />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </>
  );
};
