import { FC, MouseEvent, useRef } from "react";
import Transition, {
  TransitionStatus,
} from "react-transition-group/Transition";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setDestinationFolder } from "store/folders";

import { ContextMenu } from "components";
import { Icon } from "components/UI";
// import { IconName } from "components/UI/Icon";
import { MenuItem, SigningsType, TSharedDocumentStatus } from "types";
import { useActions } from "hooks";
import { opacityTransitionConfig } from "utils";

import styles from "./styles.module.scss";

enum RowActionsEnum {
  "share",
  "move",
  "download",
  "rename",
  "three-dots-horizontal",
  "restore",
  "edit",
  "trash",
}

type Points = {
  x: number;
  y: number;
};

type RowActionsProps = {
  isShowed: boolean;
  isTrash?: boolean;
  isContact?: boolean;
  actions?: MenuItem[] | false;
  additionalActions?: MenuItem[] | false;
  isRowMenu: boolean;
  points: Points;
  handleSetRowMenu: (e: MouseEvent<HTMLElement>) => void;
  handleLeave: () => void;
  onClick?: () => void;
  id: string;
  signingsType?: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
  isViewHistory?: boolean;
  isBackup?: boolean;
};

export const RowActions: FC<RowActionsProps> = ({
  isShowed,
  isTrash,
  isContact,
  actions,
  additionalActions,
  isRowMenu,
  points,
  handleSetRowMenu,
  handleLeave,
  id,
  signingsType,
  signingsStatus,
  isViewHistory,
  isBackup,
  onClick = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { getAction } = useActions(id);
  const { t } = useTranslation("Table", {
    keyPrefix: "RowActions",
  });
  const { t: tG } = useTranslation("General");
  const nodeRef = useRef(null);
  const actionContent: Record<keyof typeof RowActionsEnum, string> = {
    share: tG("share"),
    move: tG("move"),
    download: tG("download"),
    [`three-dots-horizontal`]: tG("showMore"),
    rename: tG("rename"),
    restore: tG("restore"),
    edit: tG("edit"),
    trash: tG("delete"),
  };

  const handleClickAction = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setDestinationFolder(null));
    onClick();
  };

  return (
    <Transition
      in={isShowed}
      appear
      mountOnEnter
      unmountOnExit
      timeout={opacityTransitionConfig().timeout}
      nodeRef={nodeRef}
    >
      {(state: TransitionStatus) => (
        <div
          ref={nodeRef}
          className={styles.RowActions}
          onClick={handleClickAction}
          style={{
            ...opacityTransitionConfig().defaultStyles,
            ...opacityTransitionConfig().transitionStyles[state],
          }}
        >
          {actions &&
            actions?.map((el, index) => (
              <Icon
                key={index}
                name={el}
                className={styles.action}
                dataTooltipId={el}
                dataTooltipContent={
                  actionContent[el as keyof typeof RowActionsEnum]
                }
                dataTooltipPlace="bottom"
                action={getAction({
                  name: el,
                  isTrash,
                  isContact,
                  signingsType,
                  signingsStatus,
                  isViewHistory,
                  isBackup,
                })}
              />
            ))}
          {additionalActions && (
            <div className={styles.rowMenu}>
              <div onClick={handleSetRowMenu}>
                <Icon
                  name="three-dots-horizontal"
                  className={styles.action}
                  dataTooltipId={isRowMenu ? "" : "show_more"}
                  dataTooltipContent={t("tooltipShowMore")}
                  dataTooltipPlace="bottom"
                />
              </div>
              {isRowMenu && (
                <ContextMenu
                  theme="light"
                  items={additionalActions}
                  top={points.y + 20}
                  left={points.x - 15}
                  isFixed
                  id={id}
                  onClose={handleLeave}
                  signingsType={signingsType}
                  signingsStatus={signingsStatus}
                  isViewHistory={isViewHistory}
                  isBackup={isBackup}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Transition>
  );
};
