import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, settings } from "store";

import { Input, Icon, Modal } from "components/UI";
import { EditCompanyNameModal } from "components";

import styles from "./styles.module.scss";

export const CompanyName = () => {
  const { user } = useAppSelector(settings);
  const { t } = useTranslation("Modals", {
    keyPrefix: "EditCompanyName",
  });
  const [isEditNameModal, setIsEditNameModal] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>(
    user?.companyName || "",
  );

  useEffect(() => {
    if (user) {
      setCompanyName(user.companyName || "");
    }
  }, [user]);

  const openEditNameModal = () => {
    setIsEditNameModal(true);
  };

  const closeEditNameModal = () => {
    setIsEditNameModal(false);
  };

  const preventChange = (e: ChangeEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <Modal isShowed={isEditNameModal}>
        <EditCompanyNameModal onClose={closeEditNameModal} />
      </Modal>

      <div className={styles.CompanyName}>
        <Input
          type="text"
          name="companyName"
          label={t("companyNameLabel")}
          onChange={preventChange}
          value={companyName}
          autoComplete="off"
        />
        <Icon name="edit" className={styles.edit} action={openEditNameModal} />
      </div>
    </>
  );
};
