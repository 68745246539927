import { useAppSelector, dashboard } from "store";

import {
  AccountButton,
  TrashBinTable,
  GlobalSearchTable,
  GlobalSearch,
} from "components";

import styles from "./styles.module.scss";

export const TrashBin = () => {
  const { search } = useAppSelector(dashboard);

  return (
    <div className={styles.TrashBin}>
      <header className={styles.header}>
        <GlobalSearch />
        <AccountButton />
      </header>
      {search ? <GlobalSearchTable /> : <TrashBinTable />}
    </div>
  );
};
